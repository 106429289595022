import { Link, Spacer, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Button } from "../../../components/atoms/Button";
import { Title } from "../../../components/atoms/Title";
import { Navigate, useNavigate } from "react-router-dom";
import { useLogin } from "../context/login.context";
import { LoginWrapper } from "./LoginWrapper";

export function ConfirmAgreement() {
    const navigate = useNavigate()

    const { validatedAgreement, setValidatedDocument, setValidatedAgreement, setValidatedPromocode} = useLogin();

    if (!validatedAgreement) {
        return(<Navigate to="/login" replace={true} />);
    }

    return (
        <LoginWrapper>
            <VStack width={{ base: "100%", lg: "60%" }} height="100%" spacing={7}>
                <Title fontSize="28px">Clube {validatedAgreement.name}!</Title>
                <Text textAlign="justify">
                    Parabéns! Este código pertence ao Clube {validatedAgreement.name}. Para
                    aproveitar todas as vantagens do Clube, continue o cadastro.
                </Text>
                <Spacer />
                <Button
                    marginTop="auto"
                    width="100%"
                    backgroundColor={validatedAgreement.secondaryColor}
                    color={validatedAgreement.secondaryFontColor}
                    _hover={{
                        backgroundColor: validatedAgreement.secondaryColor
                    }}
                    onClick={() => navigate('/signup')}
                >
                    Continuar
                </Button>
                <Link
                    color="white"
                    href="#"
                    fontSize="14px"
                    textDecoration="underline !important"
                    onClick={() => {
                        setValidatedDocument(null);
                        setValidatedAgreement(null);
                        setValidatedPromocode(null);
                        navigate('/login')
                    }}
                >
                    Cancelar
                </Link>
            </VStack>
        </LoginWrapper>
    );
}
