import React from "react";
import { Text, Box, HStack, VStack, Image } from "@chakra-ui/react";
import { useTheme } from "../../../context/index";
import { Title } from "../../../components/atoms/Title";
import { HourBlocks } from "../../../components/atoms/HourBlocks";

export const CardPromotions = ({
    type,
    discount,
    name,
    image,
    description,
    available_hours,
    onClick
}) => {
    const { primaryColor, secondaryColor } = useTheme();

    function getTitle() {
        if (type === "buy_and_gain") return "Compre e Ganhe!";
        else if (type === "treat") return "Mimo!";

        return (
            <Text
                fontFamily="Montserrat"
                letterSpacing="0.02rem"
                textTransform="uppercase"
                fontWeight="bold"
            >
                <span style={{ fontSize: "10pt" }}>{discount}</span>% de
                desconto!
            </Text>
        );
    }

    return (
        <VStack
            borderRadius="md"
            p="10px"
            border="1.5px dashed #E5E5E5"
            w="100%"
            onClick={onClick}
        >
            <HStack width="100%">
                {image ? (
                    <Image
                        marginRight="20px"
                        width={{ base: "150px", lg: "200px" }}
                        height={{ base: "100px", lg: "150px" }}
                        borderRadius="10px"
                        objectFit="cover"
                        src={image}
                    />
                ) : (
                    <></>
                )}
                <VStack
                    bgColor="white"
                    transition="0.2s"
                    cursor="pointer"
                    alignItems="flex-start"
                    width="100%"
                    spacing={0}
                >
                    <HStack alignItems="center" spacing={3}>
                        <i
                            className="fas fa-tag"
                            style={{ color: primaryColor }}
                        />
                        <Title
                            fontSize="8pt"
                            textAlign="left"
                            color={primaryColor}
                        >
                            {getTitle()}
                        </Title>
                    </HStack>
                    <Text textAlign="left" pt="5px" fontSize="12px">
                        <b>{name}</b> <Text dangerouslySetInnerHTML={{ __html: description }} />
                    </Text>
                    {/*<Box display={{ base: "none", lg: "block" }}>
                        <HourBlocks hours={available_hours} />
                </Box>*/}
                </VStack>
            </HStack>
            {/*<Box width="100%" display={{ base: "flex", lg: "none" }}>
                <HourBlocks hours={available_hours} />
                </Box>*/}
        </VStack>
    );
};
