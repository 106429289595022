import { HStack, Spinner, Switch, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { partiuClient } from "../../../axios";
import { useProfile } from "../../../context/user";

export function Preferences() {
    const {
        allowedPushNotifications,
        allowedPromotionalEmails,
        allowedWhatsapp,
        refreshProfile
    } = useProfile();
    const [loading, setLoading] = useState(false);

    function togglePreferences(key, parsedKey, value) {
        setLoading(true);
        partiuClient.profile
            .update({ [key]: value })
            .then(() => refreshProfile())
            .finally(() => setLoading(false));
    }

    if (loading) return <Spinner />;

    return (
        <VStack width={{ base: "100%", lg: "92%" }} spacing={5}>
            <Text
                color="#495057"
                fontSize="15px"
                opacity="0.7"
                textAlign="left"
                width="100%"
            >
                Altere regras de notificação e outras configurações extras.
            </Text>
            <HStack paddingTop="10px" width="100%" spacing={8}>
                <Switch
                    isChecked={allowedPushNotifications}
                    size="lg"
                    margin="0px"
                    onChange={() =>
                        togglePreferences(
                            "allowed_push_notifications",
                            "allowedPushNotifications",
                            !allowedPushNotifications
                        )
                    }
                />
                <Text fontFamily="Open Sans" fontSize="18px" fontWeight="500">
                    Notificações Push
                </Text>
            </HStack>
            <HStack width="100%" spacing={8}>
                <Switch
                    isChecked={allowedPromotionalEmails}
                    size="lg"
                    margin="0px"
                    onChange={() =>
                        togglePreferences(
                            "allowed_promotional_emails",
                            "allowedPromotionalEmails",
                            !allowedPromotionalEmails
                        )
                    }
                />
                <Text fontFamily="Open Sans" fontSize="18px" fontWeight="500">
                    Emails Partiu
                </Text>
            </HStack>
            <HStack width="100%" spacing={8}>
                <Switch
                    isChecked={allowedWhatsapp}
                    size="lg"
                    margin="0px"
                    onChange={() =>
                        togglePreferences(
                            "allowed_whatsapp",
                            "allowedWhatsapp",
                            !allowedWhatsapp
                        )
                    }
                />
                <Text fontFamily="Open Sans" fontSize="18px" fontWeight="500">
                    WhatsApp
                </Text>
            </HStack>
            <Text
                align="flex-start"
                width="100%"
                textAlign="left"
                opacity="0.5"
                fontSize="8pt"
            >
                V 3.3.0
            </Text>
        </VStack>
    );
}
