import { useToast } from "@chakra-ui/react";
import React from "react";
import { useMutation } from "react-query";
import { getGeolocation } from "../utils/geolocation";
import { useTheme } from "./theme";
import { getCurrentAddress } from "../api/location";

const defaultLocationValue = {
    latitude: null,
    longitude: null,
    allowedGeolocation: false,
    error: null,
    isLoading: true,
    forcedLocation: null,
    actualLocation: {
        street: null,
        city: null,
        state: null,
        country: null,
        neighborhood: null,
        formatted_address: "Sem Localização"
    },
    forceLocation: () => { },
    hasGeolocation: () => { },
    getLocationObject: () => { }
};

const GeolocationContext = React.createContext(defaultLocationValue);

export function GeolocationProvider({ children }) {
    const { localModule } = useTheme();
    const [geolocation, setGeolocation] = React.useState(defaultLocationValue);
    const actualLocationMutation = useMutation(
        ({ longitude, latitude }) =>
            getCurrentAddress(latitude, longitude),
        {
            onSuccess(data) {
                setGeolocation({
                    ...geolocation,
                    isLoading: false,
                    actualLocation: data
                });
            },
            onError(error) {
                console.error(error);
            }
        }
    );
    const toast = useToast();

    React.useEffect(() => {
        if (!localModule) return;
        getGeolocation(true)
            .then(({ latitude, longitude }) => {
                setGeolocation({
                    ...geolocation,
                    latitude,
                    longitude,
                    allowedGeolocation: true,
                    isLoading: true
                });
                actualLocationMutation.mutateAsync({ latitude, longitude });
            })
            .catch((error) => {
                setGeolocation({
                    ...geolocation,
                    latitude: null,
                    longitude: null,
                    allowedGeolocation: false,
                    isLoading: false,
                    error
                });

                if (localModule)
                    toast({
                        title: "Permissão para localização negada",
                        description:
                            "Sem ela não é possível mostrar descontos perto de você. Permita a localização pelo seu navegador ou telefone. " +
                            error,
                        status: "warning",
                        duration: "7000",
                        variant: "left-accent",
                        position: "bottom-right",
                        isClosable: true
                    });
            });
    }, []);

    return (
        <GeolocationContext.Provider
            value={{
                ...geolocation,
                forceLocation: (location) =>
                    setGeolocation({
                        ...geolocation,
                        forcedLocation: location
                    }),
                getLocationObject: () => {
                    if (geolocation.forcedLocation)
                        return geolocation.forcedLocation;
                    return {
                        latitude: geolocation.latitude,
                        longitude: geolocation.longitude
                    };
                },
                hasGeolocation: () =>
                    geolocation.forcedLocation || geolocation.allowedGeolocation
            }}
        >
            {children}
        </GeolocationContext.Provider>
    );
}

export function useGeolocation() {
    return React.useContext(GeolocationContext);
}
