import React from "react";
import { useTheme } from "../../context/index";
import { Flex, Heading, Text, VStack } from "@chakra-ui/react";

export const BuySuccess = () => {
    const { name, id, logo } = useTheme();


    return (
        <VStack width="100%">
            <Flex
                color="white"
                alignItems="center"
                flexDirection="column"
                justifyContent="center"
                paddingTop="30px"
                boxSizing="border-box"
                paddingBottom={{ base: "0%", lg: "3%" }}
                width="100%"
            >
                <img width={300} height={300} src={logo}></img>
                <img width={300} height={300} src="assets/purchase_success.png" style={{ marginTop: 30}}></img>
                <Heading
                    marginBottom="50px"
                    maxWidth="500px !important"
                    fontWeight="500"
                    fontFamily="Open Sans !important"
                    fontSize="32px !important"
                    lineHeight="1.5em"
                    color="black"
                    textAlign="left"
                    mt={5}
                >
                    Compra realizada com sucesso!
                </Heading>
                <Text color="black">Sua compra foi realizada com sucesso, aproveite outros descontos da nossa plataforma.</Text>
            </Flex>
        </VStack>
    );
};
