import {
    Flex,
    TabPanel,
    TabPanels,
    Tabs,
    VStack,
    Box,
    Image,
    Button,
    Divider,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Spacer
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";

import axios from "axios";
import { Title } from "../../components/atoms/Title";
import { toBRLDate } from "../../utils/dates";
import { BookGrid } from "../Books/components/BookGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookReader } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { TopMenu } from "../../components/organisms/TopMenu";

export const Drm = () => {
    const [selectedBook, setSelectedBook] = useState(null);
    const navigate = useNavigate();

    const [books, setBooks] = useState([]);

    function getBooksDrm() {
        axios
            .get(`https://api.staging.partiu.com.br/api/v2/bookstore/books/`)
            .then((result) => setBooks(result.data.results));
    }

    useEffect(() => {
        getBooksDrm();
    }, []);

    return (
        <VStack>
            <TopMenu />
            <Box
                width="100%"
                height={"100vh"}
                direction="column"
                paddingTop={{ base: "60px", lg: "0px" }}
            >
                <Modal
                    size={{ base: "md", lg: "sm" }}
                    isCentered
                    isOpen={selectedBook !== null}
                    onClose={() => setSelectedBook(null)}
                >
                    <ModalOverlay />
                    <ModalContent width={{ base: "90%", lg: "600px" }}>
                        <ModalBody padding="0px">
                            <Flex
                                flexDir={{ base: "column", lg: "row" }}
                                height="100%"
                                align="stretch"
                            >
                                <Flex
                                    flex="1"
                                    backgroundColor="#ddd !important"
                                    borderTopLeftRadius="6px"
                                    borderBottomLeftRadius={{
                                        base: "0px",
                                        lg: "6px"
                                    }}
                                    borderTopRightRadius={{
                                        base: "6px",
                                        lg: "0px"
                                    }}
                                >
                                    <Image
                                        objectFit="cover"
                                        objectPosition="top"
                                        height={{ base: "250px", lg: "100%" }}
                                        flex="1"
                                        borderTopLeftRadius="6px"
                                        borderBottomLeftRadius={{
                                            base: "0px",
                                            lg: "6px"
                                        }}
                                        borderTopRightRadius={{
                                            base: "6px",
                                            lg: "0px"
                                        }}
                                        borderRight="1px solid #ddd"
                                        src={selectedBook?.cover}
                                    />
                                </Flex>
                                <VStack
                                    flex="1"
                                    gap={0}
                                    padding="20px 10px"
                                    paddingRight="15px"
                                    align="flex-start"
                                    justify="flex-start"
                                >
                                    <Title
                                        maxWidth={{ base: "100%", lg: "270px" }}
                                        fontWeight="bold"
                                        fontSize="14pt"
                                    >
                                        {selectedBook?.title.toUpperCase()}
                                    </Title>
                                    <ModalCloseButton />
                                    <Title
                                        fontSize="9pt"
                                        textTransform="capitalize"
                                        opacity="0.7"
                                    >
                                        {selectedBook?.contributors[0].name.toLowerCase()}
                                    </Title>
                                    <Divider width="100%" paddingTop="10px" />
                                    <Spacer />
                                    <Divider width="100%" paddingTop="10px" />
                                    <Box height="5px" />
                                    <Button
                                        colorScheme="green"
                                        width="100%"
                                        onClick={() =>
                                            navigate(
                                                { pathname: "/drm/ebook" },
                                                {
                                                    state: {
                                                        url: selectedBook.file,
                                                        author: selectedBook.contributors[0].name,
                                                        title: selectedBook.title,
                                                        pdf: selectedBook.pdf
                                                    }
                                                }
                                            )
                                        }
                                        leftIcon={
                                            <FontAwesomeIcon
                                                icon={faBookReader}
                                            />
                                        }
                                    >
                                        Ler
                                    </Button>
                                </VStack>
                            </Flex>
                        </ModalBody>
                    </ModalContent>
                </Modal>

                <Tabs isLazy width="100%">
                    <TabPanels padding={{ base: "0% 0%", lg: "0px 10%" }}>
                        <TabPanel>
                            <BookGrid>
                                {books.map((book) => (
                                    <VStack
                                        key={book.id}
                                        onClick={() => {
                                            setSelectedBook(book);
                                        }}
                                        align="flex-start"
                                        width="100%"
                                        cursor="pointer"
                                    >
                                        <Flex
                                            width="100%"
                                            boxSizing="border-box"
                                            overflow="hidden"
                                            className="layered-box"
                                            backgroundColor="white"
                                            borderRadius="5px"
                                            position="relative"
                                            height={{
                                                base: "250px",
                                                lg: "310px"
                                            }}
                                        >
                                            <Image
                                                src={book.cover}
                                                objectFit={"cover"}
                                                objectPosition={"center"}
                                                transition="0.5s"
                                                width="100%"
                                                height="100%"
                                                backgroundColor="white"
                                                flexShrink="0"
                                                _hover={{
                                                    transform: "scale(1.05)"
                                                }}
                                            />
                                        </Flex>
                                        <VStack
                                            align="flex-start"
                                            paddingTop="5px"
                                            spacing={1}
                                        >
                                            <Title
                                                fontWeight="bold"
                                                fontSize={{
                                                    base: "9.5pt",
                                                    lg: "12pt"
                                                }}
                                            >
                                                {book.title.toUpperCase()}
                                            </Title>
                                            <Title
                                                opacity="0.8"
                                                fontSize={{
                                                    base: "8pt",
                                                    lg: "10pt"
                                                }}
                                                textTransform="capitalize"
                                            >
                                                {book.contributors[0].name.toLowerCase()}
                                            </Title>
                                            {book.publishing_date ? (
                                                <Title
                                                    opacity="0.6"
                                                    fontSize={{
                                                        base: "6pt",
                                                        lg: "7pt"
                                                    }}
                                                    textTransform="capitalize"
                                                >
                                                    Publicado:{" "}
                                                    {toBRLDate(
                                                        new Date(
                                                            book.publishing_date
                                                        )
                                                    )}
                                                </Title>
                                            ) : (
                                                <></>
                                            )}
                                        </VStack>
                                    </VStack>
                                ))}
                            </BookGrid>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </VStack>
    );
};
