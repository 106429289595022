import React from "react";

export const defaultSettings = {
    iosMode: false,
    isApp: false,
    isMobile: false,
    isIOS: false,
    blockNewTab: false,
    agreementApp: null
};

function loadSettings(params) {
    const storedSettings = JSON.parse(localStorage.getItem("settings") || "{}");
    if(Object.keys(params).length >= 1 && params.isApp) {
        params.isMobile = false
    }
    if (Object.keys(params).length === 0 && !storedSettings.isApp) {
        const ua = navigator.userAgent;
        if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
            params.isApp = true;
            params.isMobile = true;
        } else if (
            /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
                ua
            )
        ) {
            params.isApp = true;
            params.isMobile = true;
        } else {
            params.isApp = false;
        }
    }
    const newSettings = {
        ...defaultSettings,
        ...storedSettings,
        ...params
    };

    localStorage.setItem("settings", JSON.stringify(newSettings));

    return newSettings;
}

export const SettingsContext = React.createContext(defaultSettings);

export function SettingsProvider({ children }) {
    const settings = React.useMemo(() => {
        const obj = Object.fromEntries(
            new URLSearchParams(window.location.search)
        );

        Object.entries(obj).forEach(([key, value]) => {
            try {
                obj[key] = JSON.parse(value);
            } catch {
                obj[key] = value;
            }
        });

        return loadSettings(obj);
    }, [window.location.search]);

    return (
        <SettingsContext.Provider value={settings}>
            {children}
        </SettingsContext.Provider>
    );
}

export function useSettings() {
    return React.useContext(SettingsContext);
}
