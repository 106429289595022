import { Flex, Image, VStack } from "@chakra-ui/react";
import React from "react";
import { Navigate } from "react-router-dom";
import { useProfile } from "../../../context/user";
import { useSettings, useTheme } from "../../../context";

export function LoginWrapper({children}) {
    const { logged } = useProfile();
    const { isIOS } = useSettings();
    const { logo, integratedLogin, primaryColor, secondaryColor } = useTheme();

    if(integratedLogin)return <Navigate to={{ pathname: "/login/parceiro" }} />;

    if (logged) return <Navigate to={{ pathname: "/" }} />;

    return (
        <VStack
            forcePrimaryColor={primaryColor}
            forceSecondaryColor={secondaryColor}
            marginTop={isIOS ? "-35px" : "0px"}

            justify="center"
            align="center"
            direction="column"
            overflowY="auto"
            margin="auto"
            paddingBottom={"50px"}
            width={{ base: "100%", lg: "50%" }}
        >
            <Flex justify="center" align="center" width="100%" height="100%">
                <Flex
                    display={{ base: "none", md: "flex" }}
                    flex="1"
                    height="100%"
                    backgroundColor="#FAFAFA"
                    justify="center"
                    align="center"
                >
                    <Image
                        w="300px"

                        objectFit="contain"
                        src={logo}
                    />
                </Flex>
                <Flex
                    direction="column"
                    spacing={10}
                    justify="center"
                    width="100%"
                    align="center"
                    minHeight="100%"
                >
                    <Flex justify="center" align="center" minH="250px" flex="1">
                        <Image
                            maxW="300px"
                            // w="30%"
                            objectFit="contain"
                            src={logo}
                        />
                    </Flex>
                    <VStack flex="1" width="80%">
                        {children}
                    </VStack>
                </Flex>
            </Flex>
        </VStack>
    );
}
