import {
    Button,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Select,
    Skeleton,
    Text,
    useDisclosure,
    VStack
} from "@chakra-ui/react";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { getCity, getState } from "../../api/location";
import { useTheme } from "../../context";
import { useGeolocation } from "../../context/geolocation";
import { Title } from "../atoms/Title";

export function CurrentLocation() {
    const { primaryColor, primaryFontColor, localModule } = useTheme();
    const [location, setLocation] = useState({});
    const disclosure = useDisclosure();
    const geolocation = useGeolocation();
    const statesMutation = useMutation(() => getState());
    const citiesMutation = useMutation(() => getCity(location.state || ""));

    useEffect(() => {
        if (!localModule) return;

        if (geolocation.forcedLocation) {
            setLocation({
                state: geolocation.forcedLocation.state,
                city: geolocation.forcedLocation.city
            });
        }
        statesMutation.mutate();
    }, []);

    useEffect(() => {
        if (!localModule) return;
        setLocation({ ...location, city: null });
        citiesMutation.mutate();
    }, [location.state]);

    if (!localModule) return <></>;

    return (
        <>
            <Modal onClose={disclosure.onClose} {...disclosure}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Trocar Localização</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={5}>
                            <Select
                                value={location.state}
                                onChange={(e) =>
                                    setLocation({
                                        ...location,
                                        state: e.target.value
                                    })
                                }
                            >
                                <option>Selecione um Estado</option>
                                {(statesMutation?.data || []).map((s) => (
                                    <option key={s.state} value={s.state}>{s.name}</option>
                                ))}
                            </Select>
                            <Select
                                value={location.city}
                                onChange={(e) =>
                                    setLocation({
                                        ...location,
                                        city: e.target.value
                                    })
                                }
                                disabled={
                                    !location.state || citiesMutation.isLoading
                                }
                            >
                                <option>Selecione uma Cidade</option>
                                {(citiesMutation?.data || [])
                                    .filter((c) => c)
                                    .map((c) => (
                                        <option key={c} value={c}>{c}</option>
                                    ))}
                            </Select>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            onClick={() => {
                                geolocation.forceLocation(null);
                                setLocation({});
                                disclosure.onClose();
                            }}
                            variant="ghost"
                        >
                            Limpar
                        </Button>
                        <Button
                            marginLeft="30px"
                            color={primaryFontColor}
                            backgroundColor={primaryColor}
                            disabled={!location.state}
                            onClick={() => {
                                geolocation.forceLocation(location);
                                disclosure.onClose();
                            }}
                        >
                            Aplicar
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <VStack
                cursor="pointer"
                onClick={disclosure.onOpen}
                spacing={1}
                align="flex-start"
            >
                <HStack spacing={3} width="100%" align="center">
                    <FontAwesomeIcon
                        color="rgba(0,0,0,0.75)"
                        size="lg"
                        icon={faMapMarkerAlt}
                    />
                    {(geolocation.isLoading && !(location.state || location.city)) ? (
                        <Skeleton width="150px" height="30px" />
                    ) : (
                        <Title
                            borderBottom="1px solid rgba(0,0,0,0.35)"
                            color="rgba(0,0,0,0.75)"
                            margin="0px"
                            padding="0px"
                            fontSize="12pt"
                            paddingBottom="1px"
                        >
                            {geolocation.forcedLocation !== null
                                ? geolocation.forcedLocation.city ||
                                geolocation.forcedLocation.state
                                : geolocation.actualLocation.formatted_address.split(
                                    " - "
                                )[0]}
                        </Title>
                    )}
                </HStack>
                <Text fontSize="7pt" opacity="0.5">
                    Clique aqui para trocar de localização.
                </Text>
            </VStack>
        </>
    );
}
