import React from "react";
import { VStack, Text, HStack } from "@chakra-ui/layout";
import { Title } from "../../../components/atoms/Title";

export const About = (props) => {
    return (
        <VStack
            paddingTop="10px"
            textAlign="left"
            alignItems="flex-start"
            w="100%"
        >
            <Text>{props.description}</Text>
            {props.site ? (
                <HStack wordBreak="break-all">
                    <i className="fas fa-at" />
                    <Text>{props.site}</Text>
                </HStack>
            ) : (
                <></>
            )}
        </VStack>
    );
};
