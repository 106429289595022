import { axiosInstanceV2 } from "../axios";

export function getBooks(page = 1, search = "", category = null, agreement = null) {
    return axiosInstanceV2.get(`/bookstore/books/?search=${search}${(agreement && "&agreement=" + agreement) || ""}&page=${page}${(category && `&categories=${category}`) || ""}`).then((result) => result.data.results);
}

export function getMostReadBooks(agreement = null) {
    return axiosInstanceV2.get(`/bookstore/books/?most_read=true${(agreement && "&agreement=" + agreement) || ""}`).then((result) => result.data.results);
}

export function getFeaturedBooks(agreement = null) {
    return axiosInstanceV2.get(`/bookstore/books/?featured=true${(agreement && "&agreement=" + agreement) || ""}`).then((result) => result.data.results);
}

export function getMyBooks(page = 1, search = "", category="", agreement = null) {
    return axiosInstanceV2.get(`/bookstore/my-books/?search=${search}&page=${page}&categories=${category}${(agreement && "&agreement=" + agreement) || ""}`).then((result) => result.data.results);
}

export function getMyMonthlyBooks(page = 1, search = "", agreement = null, limit = 12) {
    return axiosInstanceV2.get(`/bookstore/my-books/monthly?search=${search}&page=${page}&limit=${limit}${(agreement && "&agreement=" + agreement) || ""}`).then((result) => result.data.results);
}

export function addBookToLibrary(bookId, agreementID = null)
{
    return axiosInstanceV2.post(`/bookstore/my-books/?${(agreementID && "agreement_id=" + agreementID) || ""}`, { book: bookId }).then((result) => ({ status: result.status, ...result.data }));
}

export function getBookCategories(agreement = null) {
    return axiosInstanceV2.get(`/bookstore/categories/?limit=30${(agreement && "&agreement=" + agreement) || ""}`).then((result) => result.data.results);
}

export function getBookActivities(agreement = null) {
    return axiosInstanceV2.get(`/bookstore/activities/?limit=30${(agreement && "&agreement=" + agreement) || ""}`).then((result) => result.data.results);
}

export function getBooksDrm() {
    return axiosInstanceV2.get(`/bookstore/books/`);
}