import { Button } from "@chakra-ui/button";
import { Image } from "@chakra-ui/image";
import { Center, Text, VStack, Divider, HStack } from "@chakra-ui/layout";
import { Modal, ModalContent, ModalOverlay, ModalBody, ModalFooter } from "@chakra-ui/modal";
import { Progress } from "@chakra-ui/progress";
import React, { useRef, useState } from "react";
import { useTheme } from "../../../context/index";
import { axiosInstance } from "../../../axios";
import { Title } from "../../../components/atoms/Title";
import { numberToBRL } from "../../../utils/money";
import { Checkbox } from "@chakra-ui/react";

export const CinemaModal = ({
  isOpen,
  onClose,
  promotions,
  company,
  discount,
  quantity
}) => {
  const btnRef = useRef();
  const [redirectStatus, setRedirectStatus] = useState("not");
  const [acceptedRules, setAcceptedRules] = useState(false);
  const [link, setLink] = useState(null);
  const { logo, name } = useTheme();

  const promotion = promotions[0];

  function generatePaymentLinkAndRedirect() {
    axiosInstance.post("/sales/buy/", {
      discount_id: discount.id,
      quantity: quantity
    }).then(({ data }) => {
      if (data && data.url) {
        setLink(data.url)
        setRedirectStatus("done");
        window.open(data.url, "_blank");
      } else {
        console.error("Erro ao gerar link", data)
        setRedirectStatus("error");
      }
    });
  }

  const handleButtonClick = () => {
    setRedirectStatus("redirecting");
    generatePaymentLinkAndRedirect()
  };

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={onClose}
      ref={btnRef}
      closeOnOverlayClick={redirectStatus === "done"}
      id="activation-modal"
    >
      <ModalOverlay
        backgroundColor="rgba(0,0,0,0.8)"
        style={{
          backdropFilter: "blur(5px)",
          "-webkit-backdrop-filter": "blur(5px)"
        }}
      />
      <ModalContent width="90%">
        <ModalBody textAlign="center" padding="20px" paddingTop="0px">
          <VStack marginBottom="-20px" marginTop="-20px" spacing={0}>
            <Image
              src={logo}
              minWidth="100px"
              objectFit="contain"
              minHeight="100px"
              maxWidth="100px"
              maxHeight={"100px"}
              marginBottom="-50px"
              zIndex={1}
            ></Image>
            <Image
              src={company.logo}
              minWidth="100px"
              objectFit="contain"
              minHeight="100px"
              maxWidth="120px"
              maxHeight={"150px"}
            ></Image>
          </VStack>
          <Divider />
          <VStack align="flex-start" width="100%" padding="10px 0px">
            <Title>Resumo do Pedido</Title>
            <Text fontSize={14}><b>Empresa</b>: {company.show_name}</Text>
            <Text fontSize={14}><b>Quantidade</b>: {quantity}</Text>
            <Text fontSize={14}><b>Valor com desconto</b>: {numberToBRL(quantity * promotion.buy_price)}</Text>
            <Text fontSize={14}><b>Regulamento</b>:</Text>
            <Text textAlign="left" fontSize={14}>{promotion.rules}</Text>
          </VStack>
          <HStack paddingTop="20px" align="center">
            <Checkbox margin={0} onChange={(e) => setAcceptedRules(e.target.checked)} />
            <Text fontSize={14}><b>Li o regulamento e estou de acordo.</b></Text>
          </HStack>
          {redirectStatus === "redirecting" && (
            <VStack paddingTop="20px" spacing="10px">
              <Text fontSize={14}>Você está sendo redirecionado para a compra na nossa plataforma de pagamentos.</Text>
              <Progress height="10px" width="100%" size="xs" isIndeterminate />
            </VStack>
          )}
          {redirectStatus === "error" && (
            <Text color="red.500">Ocorreu um erro ao processar o pagamento. Por favor, tente novamente mais tarde.</Text>
          )}
        </ModalBody>
        <ModalFooter>
          {redirectStatus === "not" && (
            <HStack>
              <Button onClick={onClose} width="100%" variant="ghost">
                Voltar
              </Button>
              <Button
                width="100%"
                disabled={!acceptedRules}
                colorScheme="green"
                onClick={handleButtonClick}
                isLoading={redirectStatus === "redirecting"}
              >
                Comprar
              </Button>
            </HStack>
          )}
          {redirectStatus === "done" && (
            <Button

              width="100%"
              onClick={() => window.open(link, "_blank")}
            >
              Ir para página de compra
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
