import { HStack } from "@chakra-ui/react";
import React from "react";
import { Plan } from "./Plan";
import { useProfile } from "../../../context/user";

export function PlanSelector({
                                 plans,
                                 selectedPlan,
                                 setSelectedPlan
                             }) {
    const { plans: userPlans = [] } = useProfile();

    return (
        <HStack
            marginBottom="30px"
            borderRadius="10px"
            justify="space-evenly"
            width="100%"
            border="1px solid #dedede"
        >
            {plans.map((p, index) => (
                userPlans.map(up => up.id).indexOf(p.id) === -1 ?
                    <Plan
                        onClick={() => setSelectedPlan(index)}
                        name={p.name.replace("Plano ", "")}
                        active={index == selectedPlan}
                    /> : <></>
            ))}
        </HStack>
    );
}