import { HStack, VStack, useDisclosure } from "@chakra-ui/react";
import {
    faBars,
    faBook,
    faBookOpen,
    faChartPie,
    faHome,
    faMoneyBill,
    faRightToBracket,
    faSearch,
    faTicketAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSettings, useTheme } from "../../context/index";
import { useProfile } from "../../context/user";
import { Title } from "../atoms/Title";
import { MyPucharsesDrawer } from "./MyPurchasesDrawer";

function MenuIcon({ icon, text, to = "", onClick = () => {} }) {
    const location = useLocation();
    const { primaryColor } = useTheme();
    const isActive = location.pathname === to;

    return (
        <Link onClick={onClick} style={{ textDecoration: "none" }} to={to}>
            <VStack justify="baseline" align="center" spacing={1.5}>
                <FontAwesomeIcon
                    size="sm"
                    style={{ transition: "0.1s", height: "15px !important" }}
                    color={isActive ? primaryColor : "#495057"}
                    icon={icon}
                />
                <Title
                    letterSpacing="0rem"
                    fontWeight={isActive ? "800" : "600"}
                    transition="0.1s"
                    color={isActive ? primaryColor : "#495057"}
                    fontSize="9pt"
                >
                    {text}
                </Title>
            </VStack>
        </Link>
    );
}

export function BottomMenu() {
    const { isApp, isIOS } = useSettings();
    const { logged } = useProfile();
    const {
        integratedLogin,
        bookstoreModule,
        localModule,
        ecommerceModule,
        econometerModule,
        bookstoreType
    } = useTheme();
    const {
        isOpen: isPurchasesOpen,
        onClose: onPurchasesClose,
        onOpen: onPurchasesOpen
    } = useDisclosure();

    if (!isApp || (bookstoreModule && !logged)) return <></>;

    return (
        <HStack
            backgroundColor="#FAFAFA"
            borderTop="1px solid #D3D3D3"
            padding="3% 10%"
            justify="space-between"
            align="baseline"
            position="fixed"
            bottom="0px"
            zIndex="999"
            width="100%"
            paddingBottom={isIOS ? "10%" : "1%"}
            height={isIOS ? "85px" : "65px"}
        >
            <MyPucharsesDrawer
                isOpen={isPurchasesOpen}
                onClose={onPurchasesClose}
            />
            <MenuIcon icon={faHome} text="Home" to="/" />
            {logged && (localModule || ecommerceModule) && (
                <MenuIcon
                    icon={faTicketAlt}
                    text="Compras"
                    to="#"
                    onClick={onPurchasesOpen}
                />
            )}
            {localModule || ecommerceModule ? (
                <MenuIcon icon={faSearch} text="Vantagens" to="/busca" />
            ) : (
                <></>
            )}
            {bookstoreModule ? (
                <MenuIcon
                    icon={faBook}
                    text={
                        bookstoreType === "book" ? "Biblioteca" : "Biblioteca"
                    }
                    to="/biblioteca"
                />
            ) : (
                <></>
            )}

            {bookstoreModule && bookstoreType === "book" && (
                <MenuIcon
                    icon={faBookOpen}
                    text={"Livros Recebidos"}
                    to="/livros-recebidos"
                />
            )}
            {bookstoreModule && bookstoreType === "magazine" && (
                <MenuIcon
                    icon={faBookOpen}
                    text={"Revistas Recebidas"}
                    to="/revistas-recebidas"
                />
            )}

            {econometerModule ? (
                <MenuIcon
                    icon={faChartPie}
                    text="Econômetro"
                    to="/econometro"
                />
            ) : (
                <></>
            )}
            {logged ? (
                <MenuIcon icon={faBars} text="Menu" to="/perfil" />
            ) : (
                <MenuIcon
                    icon={faRightToBracket}
                    text="Login"
                    to={integratedLogin ? "/login/parceiro" : "/login"}
                />
            )}
        </HStack>
    );
}
