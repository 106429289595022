import React from "react";
import { Button, Spacer, Text, VStack } from "@chakra-ui/react";
import { Title } from "../../../components/atoms/Title";
import { useTheme } from "../../../context/index";
import { Link as LinkRouter, useNavigate } from "react-router-dom";
import { LoginWrapper } from "./LoginWrapper";

export function LoginOptionsPage() {
    const { primaryColor, name, promocodeModule, planSaleModule, bookstoreModule } = useTheme();
    const navigate  = useNavigate();

    return (
        <LoginWrapper>
            <VStack spacing={7} width={{ base: "100%", lg: "80%" }}>
                <Title alignSelf="flex-start" paddingBottom="10px">
                    Como deseja continuar:
                </Title>
                {promocodeModule ? (
                    <Button
                        backgroundColor="primary"
                        // onClick={() => setValidationType("promocode")}
                        onClick={() => navigate("/login/promocode")}
                        width="100%"
                    >
                        Com Código Promocional
                    </Button>
                ) : (
                    <></>
                )}
                <Button
                    backgroundColor="primary"
                    // onClick={() => setValidationType("cpf")}
                    onClick={() => navigate("/login/document")}
                    width="100%"
                >
                    Com CPF/CNPJ Vinculado
                </Button>
                {planSaleModule && (
                    <Button
                        backgroundColor="primary"
                        // onClick={() => {
                        //     setValidationType(null);
                        //     setStep(LoginSteps.SUBSCRIPTION_DETAIL);
                        // }}
                        onClick={() => navigate("/signup")}
                        width="100%"
                    >
                        Com Assinatura
                    </Button>
                )}
                <Spacer border={`1px solid ${primaryColor}`} />

                <Button
                    variant="outline"
                    // onClick={() => {
                    //     setValidationType("login");
                    //     setStep(LoginSteps.LOGIN);
                    // }}
                    onClick={() => navigate("/login/auth")}
                    width="100%"
                >
                    Já tenho conta
                </Button>

                {bookstoreModule && <Text paddingTop={"8px"} paddingBottom={"16px"} fontSize={12}>
                    Cancelou o seu plano com a {name}? Sem problemas{" "}
                    <LinkRouter
                        to="/auth"
                        style={{ textDecoration: "underline", color: primaryColor, fontSize: 13 }}
                    >clique aqui</LinkRouter>
                    , faça seu login e visualize todos os seus livros recebidos.
                </Text>}
                <Text
                    textAlign="left"
                    color="#777"
                    fontSize="14px"
                    fontWeight={500}
                >
                    Alguns clientes {name} possuem acessos gratuitos, verifique com
                    a operadora qual seu plano.
                </Text>
            </VStack>
        </LoginWrapper>
    );
}
