import { PartiuClient } from "@partiu-vantagens/partiu-sdk";
import axios from "axios";
import { supportsLocalStorage } from "./utils";
import { storage } from "./utils/storage";

let baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;

export const axiosInstance = axios.create({
    baseURL: baseUrl,
    validateStatus: (status) => {
        if (status === 401) {
            setAccessToken(null);
            localStorage.removeItem("token");
            localStorage.removeItem("auto-login");
            // window.location.href = "/"
        }
        return status < 400;
    }
});

export const axiosInstanceV2 = axios.create({
    baseURL: baseUrl.replace("v1", "v2"),
    validateStatus: (status) => {
        if (status === 401) {
            setAccessToken(null);
            localStorage.removeItem("token");
            localStorage.removeItem("auto-login");
            // window.location.href = "/"
        }
        return status < 400;
    }
});

export const partiuClient = new PartiuClient(false);

axiosInstance.interceptors.request.use(function (config) {
    const token = storage.getToken()
    if (token){
        config.headers.common["Authorization"] = `TOKEN ${token}`;
    } else {
        config.headers.common["Authorization"] = null;
    }
    return config;
  });


axiosInstanceV2.interceptors.request.use(function (config) {
    const token = storage.getToken()
    if (token){
        config.headers.common["Authorization"] = `TOKEN ${token}`;
    } else {
        config.headers.common["Authorization"] = null;
    }
    return config;
  });

// // Add a response interceptor
// axiosInstance.interceptors.response.use(null, function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     if (error.response.status === 401){

//     }
//     return Promise.reject(error);
//   });


export const setAccessToken = (token) => {
    // if (token === null) partiuClient.auth.logout();
    // else partiuClient.auth.login(token);

    // if (token === null)
    //     axiosInstance.defaults.headers.common["Authorization"] = null;
    // else
    //     axiosInstance.defaults.headers.common[
    //         "Authorization"
    //     ] = `TOKEN ${token}`;

    // if (token === null)
    //     axiosInstanceV2.defaults.headers.common["Authorization"] = null;
    // else
    //     axiosInstanceV2.defaults.headers.common[
    //         "Authorization"
    //     ] = `TOKEN ${token}`;
};

export function isAuthenticated() {
    return axiosInstanceV2.defaults.headers.common["Authorization"];
}
