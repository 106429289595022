import { axiosInstance } from "../axios";
import { withParams } from "./discounts";

export function getProfile(agreement_id = null, navigate) {
    if (axiosInstance.defaults.headers.common["Authorization"] === null) {
        return;
    }

    let url = "/profile/";

    if (agreement_id) url += "?agreement_id=" + agreement_id;

    return axiosInstance.get(url).then(({ data, status }) => {
        if (status === 403) {
            localStorage.clear()
            navigate("/sem-vinculo")
            // window.location.reload()
        }

        return data
    })
}

export function updateProfile(id, data) {
    const formData = new FormData();

    Object.entries(data).forEach(([k, v]) => {
        formData.append(k, v);
    });

    return axiosInstance.patch(`/users/${id}/`, formData);
}

export function createProfile(data) {
    return axiosInstance.post(`/profile/`, data);
}


export function checkUserPermission(params) {
    return axiosInstance.post(withParams('/profile/check_permission/', params))
}