import * as React from 'react';
import { storage } from '../../../utils/storage';
import { useProfile } from '../../../context/user';

const LoginContext = React.createContext();
function LoginProvider({ children }) {

  const profile = useProfile();

  const [validatedDocument, setValidatedDocument] = React.useState();
  const [validatedAgreement, setValidatedAgreement] = React.useState();
  const [validatedPromocode, setValidatedPromocode] = React.useState();

  async function login(token, user) {
    storage.setToken(token);

    if (user){
      profile.updateProfile(user)
    }
  }

  const value = { 
    validatedDocument, 
    setValidatedDocument, 
    validatedAgreement, 
    setValidatedAgreement,
    validatedPromocode,
    setValidatedPromocode,
    login
  };
  return <LoginContext.Provider value={value}>{children}</LoginContext.Provider>;
}
function useLogin() {
  const context = React.useContext(LoginContext);
  if (context === undefined) {
    throw new Error('useLogin must be used within a LoginProvider');
  }
  return context;
}
export { LoginProvider, useLogin };