import {
    Button,
    VStack,
    Text,
    useToast,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    useDisclosure,
    Input,
    FormHelperText,
    FormControl
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { removeAccount } from "../../../api/register";
import { partiuClient, setAccessToken } from "../../../axios";
import { useTheme } from "../../../context/index";
import { useProfile } from "../../../context/user";
import { useNavigate, useSearchParams } from "react-router-dom";

function RemoveAccountModal() {
    const toast = useToast();
    const disclosure = useDisclosure();
    const removeMutation = useMutation(() => removeAccount(), {
        onSuccess() {
            setAccessToken(null);
            localStorage.removeItem("user");
            window.location.reload();
        },
        onError() {
            toast({
                title: "Não foi possível apagar seus dados.",
                description:
                    "Entre em contato conosco pelo email suporte@partiu.com.br para pedir sua remoção de dados. O prazo máximo é de cinco dias úteis."
            });
        }
    });

    return (
        <>
            <AlertDialog {...disclosure}>
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader>Tem certeza?</AlertDialogHeader>
                        <AlertDialogBody>
                            Essa ação apagará todos os dados relacionados a sua
                            conta em nosso banco de dados, inclusive seu acesso
                            aos clubes. Caso recrie sua conta, você não terá
                            mais acesso ao Clube e deverá consultar a reativação
                            com a sua empresa.
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button onClick={disclosure.onClose}>
                                Cancelar
                            </Button>
                            <Button
                                colorScheme="red"
                                onClick={removeMutation.mutate}
                                ml={3}
                            >
                                Deletar meus Dados
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <Text
                color="red"
                textDecoration="underline"
                fontSize="8pt"
                cursor="pointer"
                onClick={disclosure.onOpen}
            >
                Quero apagar minha conta
            </Text>
        </>
    );
}

export function ProfileData() {

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const { register, handleSubmit, setValue } = useForm({
        defaultValues: {
            full_name: '',
            cpf: '',
            telephone: '',
            email: ''
        }
    });
    const { name, email, telephone, cpf, refreshProfile } = useProfile();
    const { primaryColor, primaryFontColor } = useTheme();
    const toast = useToast();
    const profileMutation = useMutation(
        (data) => {
            // TODO: improve this validation
            const updatedFields = {}
            if (name !== data.full_name) updatedFields["full_name"] = data.full_name;
            if (cpf !== data.cpf) updatedFields["cpf"] = data.cpf;
            if (email !== data.email) updatedFields["email"] = data.email;
            if (telephone !== data.telephone) updatedFields["telephone"] = data.telephone;

            return partiuClient.profile.update(updatedFields)
        },
        {
            onSuccess(data) {
                toast({
                    title: "Dados atualizados com sucesso!",
                    description: "Seus dados foram atualizados com sucesso",
                    status: "success",
                    position: "top",
                    containerStyle: {
                        marginTop: "30px"
                    }
                });
                refreshProfile();
                if (searchParams.get("redirectUrl")){
                    navigate(searchParams.get("redirectUrl"))
                }
            }
        }
    );

    useEffect(() => {
        if (name) setValue("full_name", name);
        if (email) setValue("email", email);
        if (cpf) setValue("cpf", cpf);
        if (telephone) setValue("telephone", telephone);

    }, [name, email, cpf, telephone, setValue]);

    return (
        <form onSubmit={handleSubmit(profileMutation.mutate)}>
            <VStack width={{ base: "100%", lg: "92%" }} spacing={5} textAlign={"left"}>
                <Text
                    color="#495057"
                    fontSize="15px"
                    opacity="0.7"
                    textAlign="left"
                    width="100%"
                >
                    Verifique aqui todos seus dados pessoais vinculados à
                    Partiu. Alguns campos não podem ser editados
                </Text>
                <FormControl 
                    isInvalid={profileMutation.error && profileMutation.error.response.data && profileMutation.error.response.data['full_name']}
                    isDisabled={name}
                >
                    <Input 
                        placeholder="Nome Completo" 
                        errorBorderColor='red.300'
                        {...register("full_name")}
                    />
                    {profileMutation.error && profileMutation.error.response.data && profileMutation.error.response.data['full_name'] && (
                        <FormHelperText color="red.300" >
                            {profileMutation.error && Array.isArray(profileMutation.error.response.data['full_name']) && profileMutation.error.response.data && profileMutation.error.response.data['full_name'].join('; ')}
                        </FormHelperText>
                    )}
                </FormControl>
                <FormControl 
                    isInvalid={profileMutation.error && profileMutation.error.response.data && profileMutation.error.response.data['email']}
                    isDisabled={email}
                >
                    <Input 
                        placeholder="Email" 
                        errorBorderColor='red.300'
                        {...register("email")}
                    />
                    {profileMutation.error && profileMutation.error.response.data && profileMutation.error.response.data['email'] && (
                        <FormHelperText color="red.300" >
                            {profileMutation.error && Array.isArray(profileMutation.error.response.data['email']) && profileMutation.error.response.data && profileMutation.error.response.data['email'].join('; ')}
                        </FormHelperText>
                    )}
                </FormControl>
                <FormControl 
                    isInvalid={profileMutation.error && profileMutation.error.response.data && profileMutation.error.response.data['cpf']}
                    isDisabled={cpf}
                >
                    <Input 
                        placeholder="CPF" 
                        errorBorderColor='red.300'
                        {...register("cpf")}
                    />
                    {profileMutation.error && profileMutation.error.response.data && profileMutation.error.response.data['cpf'] && (
                        <FormHelperText color="red.300" >
                            {profileMutation.error && Array.isArray(profileMutation.error.response.data['cpf']) && profileMutation.error.response.data && profileMutation.error.response.data['cpf'].join('; ')}
                        </FormHelperText>
                    )}
                </FormControl>
                {/* TODO: enable again masked input for telehone*/}
                <FormControl isInvalid={profileMutation.error && profileMutation.error.response.data && profileMutation.error.response.data['telephone']}>
                    <Input 
                        placeholder="Telefone" 
                        errorBorderColor='red.300'
                        {...register("telephone")}
                    />
                    {profileMutation.error && profileMutation.error.response.data && profileMutation.error.response.data['telephone'] && (
                        <FormHelperText color="red.300" >
                            {profileMutation.error && Array.isArray(profileMutation.error.response.data['telephone']) && profileMutation.error.response.data && profileMutation.error.response.data['telephone'].join('; ')}
                        </FormHelperText>
                    )}
                </FormControl>
                <Button
                    type="submit"
                    backgroundColor={primaryColor}
                    color={primaryFontColor}
                    width="100%"
                    isLoading={profileMutation.isLoading}
                >
                    Atualizar
                </Button>
                <RemoveAccountModal />
            </VStack>
        </form>
    );
}
