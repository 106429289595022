import {
    Button,
    Image,
    Input,
    Select,
    Stack,
    useToast,
    VStack
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTheme } from "../../context";
import { getYoucastPartners, loginYoucast } from "../../api/youcast";
import { useLocation } from "react-router-dom";

export function YoucastLogin() {
    const location = useLocation()
    const { register, handleSubmit, getValues } = useForm();
    const { thirdPartyLogin, primaryColor, primaryFontColor } = useTheme();
    const toast = useToast();
    const [isLoading, setIsLoading] = useState(false)

    const [list, setList] = useState([])

    const getList = async () => {
        const data = await getYoucastPartners()
        setList(data)
    }

    const submitLogin = async ({ username, password, isp }) => {
        try {
            setIsLoading(true)
            const logged = await loginYoucast({
                idcompany: isp,
                password,
                username
            })
            setIsLoading(false)
            const slug = window.location.hostname.split(".")[0]
            localStorage.setItem(
                "auto-login",
                JSON.stringify({ token: logged.token, slug })
            );

            redirect(logged.token);
        } catch (error) {
            setIsLoading(false)
            if (error.response.status === 403) {
                toast({
                    title: "Erro",
                    description:
                        "Usuário não está habilitado à usar o convênio Playhub. Entre em contato com o suporte.",
                    status: "error"
                });
            } else {
                toast({
                    title: "Erro",
                    description:
                        "Não foi possível fazer login com os dados colocados. Reveja e tente novamente",
                    status: "error"
                });
            }
        }
    }

    function redirect(token) {
        window.location.href = `/?token=${token}`;
    }
    useEffect(() => {
        getList()
        let savedData = localStorage.getItem("auto-login");

        if (!savedData) return;

        try {
            savedData = JSON.parse(savedData);
            if (savedData.slug && savedData.token)
                redirect(savedData.token);
        } catch {
            localStorage.removeItem("auto-login");
        }
    }, []);

    return (
        <VStack
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="fixed"
            left="0px"
            top="0px"
            width="100%"
            height="100%"
            zIndex="100"
            backgroundColor="#FAFAFA"
        >
            <form onSubmit={handleSubmit(submitLogin)}>
                <VStack
                    backgroundColor="white"
                    borderRadius="5px"
                    boxShadow="0px 0px 10px rgba(0,0,0,0.3)"
                    padding="20px"
                    spacing={3}
                >
                    <Image
                        height="120px"
                        src="https://partiu-static.s3.amazonaws.com/Logos/logoPlayhub.png"
                    />
                    <Select required {...register("isp")} paddingTop="15px">
                        <option>Selecione um provedor</option>
                        {(list || []).map((a) => (
                            <option key={a.id} value={a.id}>
                                {a.name}
                            </option>
                        ))}
                    </Select>
                    <Stack direction={{ base: "column", lg: "row" }}>
                        <Input
                            {...register("username")}
                            placeholder="Username"
                            required
                        />
                        <Input
                            {...register("password")}
                            placeholder="Password"
                            type="password"
                            required
                        />
                    </Stack>
                    <Button
                        type="submit"
                        isLoading={isLoading}
                        isFullWidth
                        backgroundColor={primaryColor}
                        color={primaryFontColor}
                    >
                        Fazer Login
                    </Button>
                </VStack>
            </form>
        </VStack>
    );
}
