import { Heading, Stack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { useTheme } from "../../context/index";

export function Banner({ title }) {
    const { primaryColor, secondaryColor } = useTheme();

    return (
        <Stack
            alignItems="flex-start"
            justifyContent="flexstart"
            spacing={{ base: 20, lg: 40 }}
            width="100%"
            padding="60px 5%"
            background={
                "linear-gradient(135deg, " +
                secondaryColor +
                " 0%," +
                primaryColor +
                " 100%)"
            }
            direction={{ base: "column", lg: "row" }}
        >
            <Heading
                fontWeight="600"
                fontFamily="Open Sans !important"
                fontSize="24pt !important"
                color="white"
                lineHeight="1.5em"
                textAlign="left"
            >
                {title}
            </Heading>
        </Stack>
    );
}
