import { VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Navigate } from "react-router-dom";
import { partiuClient } from "../../axios";
import { useProfile } from "../../context/user";
import { Receipts } from "./components/Receipts";
import { Summary } from "./components/Summary";
import { UserDatePicker } from "./components/UserDatePicker";
import { TopMenu } from "../../components/organisms/TopMenu";

export function EconometerPage() {
    const profile = useProfile();
    const econometer = useQuery("econometer", async () => {
        return await partiuClient.profile.econometer();
    });

    useEffect(() => {
        profile.refreshProfile();
    }, []);

    return (
        <>
            <TopMenu />
            <VStack
                direction="column"
                align="center"
                justify="center"
                paddingBottom="100px"
                paddingTop={{ base: "70px", lg: "0px" }}
                width={{ base: "100%", lg: "50%" }}
                margin="auto"
                overflow="none"
            >

                <UserDatePicker />
                <Summary econometer={econometer} />
                <Receipts econometer={econometer} />
            </VStack></>

    );
}
