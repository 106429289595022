const setUser = (user) => localStorage.setItem("user", JSON.stringify(user));
const getUser = () => JSON.parse(localStorage.getItem("user"));

const setToken = (token) => localStorage.setItem("token",token);
const getToken = () => localStorage.getItem("token");
const removeToken = () => localStorage.removeItem("token");

const setAutoLogin = (user) => localStorage.setItem("auto-login", JSON.stringify(user));
const getAutoLogin = () => JSON.parse(localStorage.getItem("auto-login"));

export const storage = {
setUser,
  getUser,
  getToken,
  setToken,
  removeToken,
  setAutoLogin,
  getAutoLogin
}

