import {
    Button,
    CircularProgress,
    Flex,
    OrderedList,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Text,
    VStack
} from "@chakra-ui/react";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { generateFixedColorGradient } from "color-extender-js";
import React from "react";
import { Chart } from "react-chartjs-2";
import { Title } from "../../../components/atoms/Title";
import { EconometerBar } from "../../../components/molecules/EconometerBar";
import { useTheme } from "../../../context/index";
import { mixArrays } from "../../../utils/arrays";
import { portugueseMonthLabels } from "../../../utils/dates";
import { numberToBRL } from "../../../utils/money";

export function Summary({ date, econometer }) {
    if (econometer.isLoading) {
        return <CircularProgress isIndeterminate />;
    }

    return (
        <VStack textAlign="left" width="90%">
            <VStack paddingTop="30px" width="100%">
                <Title width="100%" textAlign="left">
                    Sua economia até hoje:
                </Title>
                <Title
                    fontWeight="700"
                    textAlign="left"
                    width="100%"
                    fontSize="20pt"
                >
                    {numberToBRL(
                        econometer.data.progress[
                            econometer.data.progress.length - 1
                        ].total
                    )}
                </Title>
            </VStack>
            <VStack
                paddingTop="30px"
                paddingBottom="30px"
                align="flex-start"
                width="100%"
                spacing={5}
            >
                <Title>Seu progresso esse mês:</Title>
                <EconometerBar
                    barHeight="30px"
                    barFontSize="14px"
                    subtitleFontSize="12px"
                />
            </VStack>
            <Title width="100%" textAlign="left">
                Gráfico mensalidade vs economia:
            </Title>

            <Flex paddingBottom="10px" width="100%" overflowY="scroll">
                <Chart
                    type="bar"
                    height="200px"
                    options={{
                        scales: {
                            y: {
                                max:
                                    Math.max(
                                        ...econometer.data.progress.map((p) =>
                                            parseFloat(p.progress)
                                        )
                                    ) + 50,
                                display: false
                            }
                        },
                        plugins: {
                            datalabels: {
                                font: { size: "10px", weight: "900" },
                                color: "rgb(75, 192, 192)",
                                borderRadius: 10,
                                anchor: "end",
                                align: "top",
                                display: (context) => {
                                    let value =
                                        context.dataset.data[context.dataIndex];
                                    if (
                                        context.datasetIndex === 0 ||
                                        value === 0
                                    )
                                        return false;

                                    return "block";
                                },
                                formatter: (value, context) => {
                                    return numberToBRL(value);
                                }
                            }
                        }
                    }}
                    data={{
                        labels: econometer.data.progress.map((p) => {
                            const [month, year] = p.label.split("/");

                            return (
                                portugueseMonthLabels[
                                    parseInt(month) - 1
                                ].substring(0, 3) +
                                "/" +
                                year.substring(2, 4)
                            );
                        }),
                        datasets: [
                            {
                                label: `Mensalidade Aprox. ${numberToBRL(
                                    econometer.data.target
                                )}`,
                                data: new Array(
                                    econometer.data.progress.length
                                ).fill(econometer.data.target),
                                backgroundColor: "rgb(255, 99, 132)"
                            },
                            {
                                label: "Economia",
                                data: econometer.data.progress.map(
                                    (p) => p.progress
                                ),
                                backgroundColor: "rgb(75, 192, 192)"
                            }
                        ]
                    }}
                />
            </Flex>
        </VStack>
    );
}
