import {
    Box,
    Button,
    Divider,
    Flex,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Spacer,
    useToast,
    VStack
} from "@chakra-ui/react";
import React from "react";
import { Title } from "../../../components/atoms/Title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookReader, faDownload } from "@fortawesome/free-solid-svg-icons";
import { useMutation } from "react-query";
import { addBookToLibrary } from "../../../api/books";
import { useProfile } from "../../../context/user";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../../context";

export function BookModal({ id, title, author, agreement, file, pdf, cover, description, isOpen, onClose }) {
    const toast = useToast();

    const { logged } = useProfile();
    const navigate = useNavigate();
    const { integratedLogin, id:agreementID } = useTheme();
    const addToLibraryMutation = useMutation(() => addBookToLibrary(id, agreementID), {
        onSuccess(data) {
            if (data.status === 400) {
                toast({
                    title: "Oops",
                    description: "Esse livro não está disponível em seu plano. Para ter acesso à esse livro melhore seu plano."
                });
                return navigate({ pathname: "/combos" })
            }
            if (data.status === 403) return navigate({ pathname: "/combos" }, { state: { showToast: true } });
            navigate(
                { pathname: "/drm/ebook" },
                {
                    state: {
                        url: data.file,
                        author,
                        title: title,
                        pdf: pdf
                    }
                }
            )
        },
        onError(error) {
            navigate({ pathname: "/combos" });
        }
    });

    function openBook(file) {
        window.open(file, "_blank");
    }

    function onAddToLibrary() {
        if (!logged)
            return navigate(
                { pathname: integratedLogin ? "/login/parceiro" : "/login" },
                { state: { showToast: true } }
            );

        /*if (agreement) return openBook(file)*/

        addToLibraryMutation.mutate();
    }

    if (!title) return <></>;

    return (
        <Modal size={{ base: "md", lg: "sm" }} isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent width={{ base: "90%", lg: "600px" }}>
                <ModalBody padding="0px">
                    <Flex flexDir={{ base: "column", lg: "row" }} height="100%" align="stretch">
                        <Flex
                            flex="1"
                            backgroundColor="#ddd !important"
                            borderTopLeftRadius="6px"
                            borderBottomLeftRadius={{ base: "0px", lg: "6px" }}
                            borderTopRightRadius={{ base: "6px", lg: "0px" }}>
                            <Image
                                objectFit="cover"
                                objectPosition="top"
                                height={{ base: "250px", lg: "100%" }}
                                flex="1"
                                borderTopLeftRadius="6px"
                                borderBottomLeftRadius={{ base: "0px", lg: "6px" }}
                                borderTopRightRadius={{ base: "6px", lg: "0px" }}
                                borderRight="1px solid #ddd"
                                src={cover} />
                        </Flex>


                        <VStack flex="1" gap={0} padding="20px 10px" paddingRight="15px" align="flex-start"
                            justify="flex-start">
                            <Title maxWidth={{ base: "100%", lg: "270px" }} fontWeight="bold"
                                fontSize="14pt">{title.toUpperCase()}</Title>
                            <ModalCloseButton />
                            <Title fontSize="9pt" textTransform="capitalize"
                                opacity="0.7">{author?.toLowerCase()}</Title>
                            <Divider width="100%" paddingTop="10px" />
                            <Title paddingTop="10px" fontWeight="bold" fontSize="12pt">Sobre</Title>
                            <Box
                                style={{ fontSize: "10pt", maxHeight: "200px", overflowY: "auto", overflowX: "hidden" }}
                                dangerouslySetInnerHTML={{ __html: description }} />
                            <Spacer />
                            <Divider width="100%" paddingTop="10px" />
                            <Box height="5px" />
                            <Button onClick={onAddToLibrary} isLoading={addToLibraryMutation.isLoading}
                                colorScheme="green" width="100%"
                                leftIcon={<FontAwesomeIcon icon={faBookReader} />}>Ler Agora</Button>

                        </VStack>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
