import React from "react";
import { Box, Divider, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay, HStack, Image, VStack, DrawerCloseButton, Link, Modal, ModalOverlay, ModalContent, ModalCloseButton, useDisclosure, ModalHeader, Button, Text } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { listMyVouchers } from "../../api/vouchers";
import { Title } from "../atoms/Title";
import { toBRLDate } from "../../utils/dates";
import { useProfile } from "../../context/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { ModalBody, ModalTitle } from "react-bootstrap";
import { useTheme } from "../../context";

function Purchase({ purchase }) {
    const howToUseModalDisclosure = useDisclosure();
    const { primaryColor, primaryFontColor, secondaryColor } = useTheme();
    const link = React.useMemo(() => {
        if (purchase.sale.discount.mode === "local") return "/detalhes/local/" + purchase.sale.discount.stores[0];

        return "/detalhes/online/" + purchase.sale.discount.comapany.id
    }, [purchase])
    return (
        <VStack align="flex-start" className="layered-box" borderRadius="5px" border="1px solid #eee">
            <Modal {...howToUseModalDisclosure}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalHeader>
                        <ModalTitle>
                            Como Usar
                        </ModalTitle>
                    </ModalHeader>
                    <ModalBody >
                        <Text dangerouslySetInnerHTML={{ __html: purchase.sale.discount?.how_to_use?.replaceAll("\n", "<br/>") }} />
                    </ModalBody>
                </ModalContent>
            </Modal>
            <HStack align="flex-start" padding="20px" paddingBottom="10px">
                <Box>
                    <Image borderRadius="5px" objectFit="cover" boxSize="100px" src={purchase.sale.discount.image} />
                </Box>
                <VStack align={"flex-start"}>
                    <Title fontSize="16pt">{purchase.sale.discount.company.name}</Title>
                    <Title fontSize="8pt">Comprado em: {toBRLDate(new Date(purchase.sale.created_at))}</Title>
                    <Title fontSize="8pt">Valido até: {toBRLDate(new Date(purchase.valid_until))}</Title>
                </VStack>
            </HStack>
            <Link href={link} paddingLeft="20px">Ver regulamento<FontAwesomeIcon style={{ marginLeft: 5 }} icon={faExternalLink} /></Link>
            {purchase.sale.discount.how_to_use && <Button transform="translateX(18px)" _selected={{ backgroundColor: secondaryColor }} onClick={howToUseModalDisclosure.onOpen} color={primaryFontColor} backgroundColor={primaryColor}>Como Usar <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faQuestionCircle} /></Button>}
            <Divider />
            <HStack align="flex-end" justify="flex-end" alignSelf="flex-end" padding="0px 20px" paddingBottom="10px">
                <Title fontSize="11pt" margin="0px" padding="0px">Voucher: {purchase.code.replace(",", "")}</Title>
            </HStack>
        </VStack>
    )
}

export function MyPucharsesDrawer({ isOpen, onClose }) {
    const { logged } = useProfile();
    const { data: purchases = [] } = useQuery("my-vouchers", () => listMyVouchers(logged));

    return (
        <Drawer size="md" isOpen={isOpen} onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent backgroundColor="#FAFAFA">
                <DrawerHeader>
                    Minhas Compras
                </DrawerHeader>
                <DrawerCloseButton />
                <DrawerBody>
                    <VStack spacing={8} width="100%">
                        {purchases?.map ? purchases.map(purchase => <Purchase purchase={purchase} />) : []}
                        {purchases?.length === 0 && <Text>{`Você ainda não possui compras!
Navegue pela nossa plataforma`}
                        </Text>}
                    </VStack>
                </DrawerBody>

            </DrawerContent>
        </Drawer>
    );
};
