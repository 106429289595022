import { Center, Checkbox, CircularProgress, Flex, Heading, Link, Stack, useToast } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { FormInput } from "../../../components/atoms/FormInput";
import { Button } from "../../../components/atoms/Button";
import { Card } from "../../../components/atoms/Card";
import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { axiosInstanceV2 } from "../../../axios";
import jwt from "jsonwebtoken";
import { useTheme } from "../../../context";
import { useProfile } from "../../../context/user";

export function CompanyPlanForm({ plan, toast, register, handleSubmit, getValues }) {
    const { id, name } = useTheme();
    const createAgreementMutation = useMutation(
        () =>
            axiosInstanceV2.post("/agreements/", {
                ...getValues(),
                supervisor: id,
                physical_card: false
            }),
        {
            onSuccess: () => {
                const values = getValues();
                const token = jwt.sign(
                    {
                        customer_email: values.owner_email,
                        customer_name: values.owner_name,
                        customer_code: values.cnpj,
                        customer_registry_code: values.cnpj
                    },
                    plan.vindi_token
                );

                window.open(plan.vindi_page + "?payload=" + token, "_blank");
            },
            onError: (error) => {
                toast({
                    title: "Oops!",
                    status: "error",
                    description:
                        "Parece que já existe um usuário criado com este email ou CPF."
                });
            }
        }
    );
    return (
        <Card padding="30px 30px" width="100%">
            <Heading fontSize="20pt">Crie seu Clube de Vantagens com a {name}</Heading>
            <form style={{ width: "100%" }} onSubmit={handleSubmit(createAgreementMutation.mutate)}>
                <Stack
                    width="100%"
                    paddingTop="30px"
                    direction={{ base: "column", lg: "row" }}
                >
                    <FormInput
                        containerProps={{ width: "100%" }}
                        label="Nome Completo *"
                        required={true}
                        hookForm={register("owner_name")}
                    />
                    <FormInput
                        hookForm={register("owner_email")}
                        required={true}
                        containerProps={{ width: "100%" }}
                        label="E-mail *"
                    />
                </Stack>
                <Stack
                    paddingTop="20px"
                    width="100%"
                    direction={{ base: "column", lg: "row" }}
                >
                    <FormInput
                        containerProps={{ width: "100%" }}
                        hookForm={register("name")}
                        required={true}
                        label="Nome da Empresa *"
                    />
                    <FormInput
                        hookForm={register("cnpj")}
                        containerProps={{ width: "100%" }}
                        required={true}
                        label="CPF *"
                        mask="999.999.999-99"
                    />
                </Stack>
                <Stack
                    paddingTop="20px"
                    width="100%"
                    direction={{ base: "column", lg: "row" }}
                >
                    <FormInput
                        containerProps={{ width: "100%" }}
                        hookForm={register("owner_telephone")}
                        required={true}
                        label="Telefone *"
                    />
                    <FormInput
                        containerProps={{ width: "100%" }}
                        label="E-mail (Equipe de Marketing)"
                        hookForm={register("marketing_email")}
                    />
                </Stack>
                <Stack
                    paddingTop="20px"
                    width="100%"
                    direction={{ base: "column", lg: "row" }}
                >
                    <FormInput
                        containerProps={{ width: "100%" }}
                        label="Plano"
                        disabled={true}
                        value={plan.name}
                    />
                    <Flex width="100%" justify="flex-end">
                        <Button
                            isLoading={createAgreementMutation.isLoading}
                            alignSelf="flex-end"
                            height="40px"
                            marginTop="30px"
                            marginLeft="auto"
                            type="submit"

                        >Pagamento {">>"}</Button>
                    </Flex>
                </Stack>
            </form>
        </Card>
    );
}

export function UserPlanForm({ plan, toast, setValue, getValues, register, handleSubmit }) {
    const { name: userName, email, cpf } = useProfile();
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const { name } = useTheme();

    function onSubmit() {
        const values = getValues();
        const token = jwt.sign(
            {
                customer_email: values.email,
                customer_name: values.name,
                customer_code: cpf,
                customer_registry_code: cpf
            },
            plan.vindi_token
        );

        window.open(plan.vindi_page + "?payload=" + token, "_blank");
    }

    useEffect(() => {
        setValue("name", userName);
        setValue("email", email);
        setValue("cpf", cpf);
    }, [userName]);

    return (
        <Card padding="30px 30px" width="100%">
            <Heading fontSize="20pt">Seja Assinante do Clube {name}</Heading>
            <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
                <Stack
                    width="100%"
                    paddingTop="30px"
                    direction={{ base: "column", lg: "row" }}
                >
                    <FormInput
                        containerProps={{ width: "100%" }}
                        label="Nome Completo *"
                        required={true}
                        hookForm={register("name")}
                    />
                    <FormInput
                        hookForm={register("email")}
                        required={true}
                        containerProps={{ width: "100%" }}
                        label="E-mail *"
                    />
                </Stack>
                <Stack
                    paddingTop="20px"
                    width="100%"
                    direction={{ base: "column", lg: "row" }}
                >
                    <FormInput
                        hookForm={register("cpf")}
                        containerProps={{ width: "100%" }}
                        required={true}
                        label="CPF *"
                        mask="999.999.999-99"
                        disabled
                    />
                    <FormInput
                        containerProps={{ width: "100%" }}
                        label="Plano"
                        disabled={true}
                        value={plan.name}
                    />
                </Stack>
                <Stack
                    paddingTop="30px"
                    width="100%"
                    direction={{ base: "column", lg: "row" }}
                >
                    <Checkbox
                        isChecked={acceptedTerms}
                        onChange={(e) => setAcceptedTerms(e.target.checked)}>Estou de acordo com os <Link
                        href="/legal/termos-de-uso" target="_blank">Termos de
                        Uso</Link></Checkbox>
                </Stack>

                <Stack
                    paddingTop="0px"
                    width="100%"
                    direction={{ base: "column", lg: "row" }}
                >
                    <Flex width="100%" justify="flex-end">
                        <Button
                            disabled={!acceptedTerms}
                            alignSelf="flex-end"
                            height="40px"
                            marginTop="30px"
                            marginLeft="auto"
                            type="submit"

                        >Pagamento {">>"}</Button>
                    </Flex>
                </Stack>
            </form>
        </Card>
    );
}

export function PlanForm({ plan }) {
    const toast = useToast();
    const { register, handleSubmit, getValues, setValue } = useForm();
    const props = { register, handleSubmit, getValues, setValue, toast, plan };

    if (!plan) return (<Center>
        <CircularProgress isIndeterminate />
    </Center>);

    return (plan.type === "company" ? <CompanyPlanForm {...props} /> : <UserPlanForm {...props} />);
}