import React, { useContext } from "react";
import { Box, Text } from "@chakra-ui/react";
import { useTheme } from "../../context/index";

export const Tag = (props) => {
    const { secondaryColor, secondaryFontColor } = useTheme();

    return (
        <Box
            border="0px"
            as="button"
            borderRadius="5px"
            p="3px 7px"
            borderColor="black.300"
            marginRight="10px"
            backgroundColor={secondaryColor}
        >
            <Text
                fontWeight="bold"
                fontSize="7pt"
                color={secondaryFontColor}
                fontFamily="Montserrat"
                letterSpacing="0.02rem"
                whiteSpace="nowrap"
            >
                {(props.tagName || "").toUpperCase()}
            </Text>
        </Box>
    );
};
