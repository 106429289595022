import React, { useRef, useState } from "react";
import {
  Text,
  Box,
  Stack,
  Flex,
  VStack,
  Center,
  HStack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Spacer,
} from "@chakra-ui/react";
import { Link as RLink, useNavigate } from "react-router-dom";
import { useTheme } from "../../context/index";
import { PromoInfo } from "./components/PromoInfo";
import { CardPromotions } from "./components/CardPromotions";
import { About } from "./components/About";
import { UseDiscount } from "./components/UseDiscounts";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { CircularProgress } from "@material-ui/core";
import { Parallax } from "react-parallax";
import { UseDiscountDrawer } from "./components/UseDiscountDrawer";
import StoreMap from "./components/Map";
import { useCheckMobile } from "../../utils";
import { RoundedIconButton } from "../../components/atoms/RoundedIconButton";
import { useProfile } from "../../context/user";
import { partiuClient } from "../../axios";
import { Title } from "../../components/atoms/Title";
import { retrievePromotion } from "../../api/discounts";

export const PromoDetails = ({ setRegisterModal }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const {
    primaryColor,
    secondaryColor,
    name,
    defaultBackgroundColor,
    integratedLogin,
  } = useTheme();
  const { mode, id } = useParams();
  const { data, isLoading } = useQuery(
    `details-${id}`,
    () => retrievePromotion(`${mode ? mode + "_" : ""}${id}`),
    {
      onSuccess(data) {
        setFavorited(data.favorite);
      },
    }
  );
  const [isFavorited, setFavorited] = useState(false);
  const favoriteButton = useRef();
  const isMobile = useCheckMobile();
  const navigate = useNavigate();
  const { logged } = useProfile();

  if (isLoading)
    return (
      <Center padding="40px">
        <CircularProgress isIndeterminate />
      </Center>
    );

  function getTitle(type, discount) {
    if (type === "buy_and_gain") return "Compre e Ganhe!";
    else if (type === "treat") return "Mimo!";

    return (
      <Text fontFamily="Montserrat" textTransform="uppercase">
        <span style={{ fontSize: "12pt" }}>{discount}</span>% de desconto!
      </Text>
    );
  }

  function toggleFavorite() {
    if (!logged)
      return navigate({
        pathname: integratedLogin ? "/login/parceiro" : "/login",
      });

    setFavorited(!isFavorited);

    partiuClient.profile.toggleFavorite(data.promotions[0].id);

    setTimeout(() => {
      if (!isFavorited) favoriteButton.current.classList.add("animate__heartBeat");
      else favoriteButton.current.classList.add("animate__flash");
      setTimeout(
        () => favoriteButton.current.classList.remove("animate__heartBeat"),
        1000
      );
    }, 100);
  }

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  return (
    <Box marginTop="-35px" backgroundColor={defaultBackgroundColor}>
      {/* <DiscountDrawer
          isOpen={selectedDiscount}
          onClose={() => setSelectedDiscount(null)}
          {...data}
          {...selectedDiscount}
        /> */}
      {data.cover_photo ? (
        <Parallax
          bgImage={`${data.cover_photo}`}
          style={{
            height: isMobile ? "40vh" : "50vh",
            backgroundImage: `url(${data.cover_photo})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          bgImageSize="cover"
          bgImageStyle={{
            height: isMobile ? "40vh" : "51vh",
            width: "100%",
            "object-fit": "contain",
          }}
        />
      ) : (
        <Box
          height="50vh"
          width="100%"
          backgroundImage={`linear-gradient(to bottom, rgba(0,0,0, 0), rgba(0,0,0, 0.5)), url(${data.cover_photo}), linear-gradient(135deg, ${primaryColor} 0%, ${secondaryColor} 100%)`}
        />
      )}
      <Stack
        backgroundColor={defaultBackgroundColor}
        paddingBottom="200px"
        direction={{ base: "row", lg: "column" }}
        width="100%"
      >
        <Stack
          w={{ base: "100%", lg: "80%" }}
          direction={{ base: "column", lg: "row" }}
          alignSelf="center"
          spacing={8}
          marginTop={{ base: "30vh", lg: "-5vh" }}
          padding="0px"
          pb="40px"
        >
          <VStack
            marginTop={{
              base: "-40vh",
              lg: "-7.8vh",
            }}
            w="100%"
            minWidth={{ base: undefined, lg: "500px" }}
            zIndex="1"
          >
            <HStack
              padding={{ base: "0px 10px", lg: 0 }}
              marginBottom="2vh"
              w="100%"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <RLink style={{ textDecoration: "none" }} to="/">
                <RoundedIconButton icon="fas fa-chevron-left" />
              </RLink>
              <Spacer />
              <RoundedIconButton
                ref={favoriteButton}
                color="#e31b23"
                iconClassName="animate__animated"
                icon={isFavorited ? "fas fa-heart" : "far fa-heart"}
                onClick={toggleFavorite}
                transform="translateX(-10px)"
              />
              {navigator.share ? (
                <RoundedIconButton
                  icon="far fa-share-alt"
                  onClick={() => {
                    try {
                      navigator.share({
                        title: `Veja esse desconto na ${data.name} pelo(a) ${name}!`,
                        text: `Desconto ${data.name}`,
                        url: window.location.href,
                      });
                    } catch {
                      console.error("Sharing canceled");
                    }
                  }}
                />
              ) : (
                <></>
              )}
            </HStack>
            <VStack
              w="100%"
              h="100%"
              border="0px"
              borderRadius="10px"
              bgColor="white"
              boxShadow="0 -5px 0.75rem rgba(0,0,0,0.2)"
              alignItems="flex-start"
              paddingBottom="20px"
              spacing={5}
            >
              <VStack padding="20px" pb="10px" w="100%">
                <Flex w="100%" alignItems="flex-start">
                  <PromoInfo {...data} />
                  <Spacer />
                </Flex>
              </VStack>
              <Tabs index={tabIndex} onChange={handleTabsChange} w="100%">
                <TabList>
                  <Tab
                    fontSize="16px"
                    fontWeight="500"
                    fontFamily="Open Sans"
                    letterSpacing="0.06rem"
                    _selected={{
                      fontWeight: "600",
                      color: primaryColor,
                      borderBottom: `2px solid ${primaryColor}`,
                    }}
                  >
                    Vantagens
                  </Tab>
                  <Tab
                    fontSize="16px"
                    fontWeight="400"
                    fontFamily="Open Sans"
                    letterSpacing="0.06rem"
                    _selected={{
                      fontWeight: "600",
                      color: primaryColor,
                      borderBottom: `2px solid ${primaryColor}`,
                    }}
                  >
                    Sobre
                  </Tab>
                  <Tab
                    fontSize="16px"
                    fontWeight="400"
                    fontFamily="Open Sans"
                    letterSpacing="0.06rem"
                    _selected={{
                      fontWeight: "600",
                      color: primaryColor,
                      borderBottom: `2px solid ${primaryColor}`,
                    }}
                    isSelected
                  >
                    Regulamento
                  </Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <VStack paddingBottom="10px" alignItems="flex-start" spacing={0}>
                      <Text textAlign="left" color="#888" fontSize="12px">
                        Aperte no desconto para mais informações
                      </Text>
                    </VStack>
                    <Stack alignItems="flex-start" w="100%" direction={{ base: "column", lg: "column" }} spacing={5}>
                      {/* Código para renderizar a guia "Todos" */}
                      {data.promotions.map((p) => (
                        <CardPromotions onClick={() => setTabIndex(2)} {...p} />
                      ))}
                    </Stack>
                  </TabPanel>
                  <TabPanel>
                    <About {...data} />
                  </TabPanel>
                  <TabPanel>
                    {data.promotions.map(({ rules, type, discount }) => (
                      <VStack overflowY="scroll" textAlign="left" padding="5px" alignItems="flex-start" spacing={2}>
                        <Title paddingTop="20px" fontSize="18px">
                          {data.show_name.toUpperCase()}
                        </Title>
                        {getTitle(type, discount)}
                        <Title paddingTop="20px" fontSize="14px">
                          REGULAMENTO
                        </Title>
                        <Text fontSize="14px" dangerouslySetInnerHTML={{ __html: rules }} />
                      </VStack>
                    ))}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </VStack>
          </VStack>

          <VStack spacing={{ base: 0, lg: 5 }}>
            <Box display={{ base: "none", lg: "block" }}>
              <UseDiscount
                setRegisterModal={setRegisterModal}
                company={data}
                category={data.category}
                promotions={data.promotions}
                coupon={data.promotions[0].coupon}
                link={data.promotions[0].link}
                discount={data.promotions[0]}
                initialValue={data.promotions[0].original_price}
                mode={mode}
              />
            </Box>

            {mode === "local" ? (
              <StoreMap
                data={data}
                center={{
                  lat: parseFloat(data.latitude),
                  lng: parseFloat(data.longitude),
                }}
              />
            ) : (
              <></>
            )}
          </VStack>
        </Stack>
      </Stack>
      <UseDiscountDrawer
        setRegisterModal={setRegisterModal}
        company={data}
        companyData={data}
        category={data.category}
        promotions={data.promotions}
        coupon={data.promotions[0].coupon}
        link={data.promotions[0].link}
        discount={data.promotions[0]}
        initialValue={data.promotions[0].original_price}
        mode={mode}
      />
    </Box>
  );
};
