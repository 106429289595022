import {
    Avatar,
    Box,
    Flex,
    Image,
    Tag,
    Text,
    useInterval,
    useToast,
    VStack
} from "@chakra-ui/react";
import {
    faCamera,
    faChevronCircleDown,
    faMedal
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import { useMutation } from "react-query";
import { partiuClient } from "../../axios";
import { useTheme } from "../../context/index";
import { useProfile } from "../../context/user";
import { SubTitle } from "../../documentation/components/Headers";
import { Gradient } from "./Gradient";
import { getProfile } from "../../api/profile";
import { useLocation, useNavigate } from "react-router-dom";
import { getMaxPlanLevelFromSubscriptions } from "../../utils/subscription";

export function UserImage({ size = 60, changeImage = false }) {
    const planColors = ["#cd7f32", "darkgrey", "#B59410"];
    const planNames = ["", "Bronze", "Prata", "Ouro"];
    const navigate = useNavigate();
    const location = useLocation();
    const { photo, logged, subscriptions } = useProfile();
    const { secondaryColor, id } = useTheme();
    const inputRef = useRef();
    const toast = useToast();
    const plan = useMemo(() =>
        Math.max(getMaxPlanLevelFromSubscriptions(subscriptions, id)), [subscriptions])
    const updateProfileMutation = useMutation(
        (data) => partiuClient.profile.update(data),
        {
            onSuccess() {
                window.location.reload();
            },
            onError(data) {
                alert(JSON.stringify(data.response.data));
                toast({
                    title: "Imagem Inválida",
                    description:
                        "Essa imagem é muito pesada ou é inválida, escolha outra e tente novamente.",
                    status: "error"
                });
            }
        }
    );

    useEffect(() => {
        getProfile(id, navigate);
    }, [id, location])

    if (!logged) return <></>;

    function changeProfileImage(e) {
        const formData = new FormData();
        formData.append("image", e.target.files[0]);
        updateProfileMutation.mutate(formData);
    }

    return (
        <VStack
            position="relative"
            onClick={() => (changeImage ? inputRef.current.click() : {})}
            spacing={0}
        >
            <input
                type="file"
                accept="image/*"
                ref={inputRef}
                onChange={changeProfileImage}
                style={{ right: 5000, position: "absolute" }}
            />
            <Gradient
                Component={Flex}
                align="center"
                justify="center"
                width={size + "px"}
                height={size + "px"}
                borderRadius="100rem"
            >
                <Avatar
                    borderRadius="100rem"
                    width={size - 4 + "px"}
                    height={size - 4 + "px"}
                    src={photo}
                />
                <FontAwesomeIcon
                    icon={changeImage ? faCamera : faChevronCircleDown}
                    style={{
                        position: "absolute",
                        bottom: "0px",
                        right: "0px",
                        color: secondaryColor,
                        filter: "drop-shadow(0px 0px 2px rgba(0,0,0,0.7))"
                    }}
                />
                {plan >= 0 ?
                    <Tag height="5px !important" color={planColors[plan]} position="absolute" left="-50%" bottom="0px" fontSize="8pt" padding="7px" fontWeight="bolder" height="0px">{planNames[plan]}</Tag>
                    : <></>}
            </Gradient>
            {changeImage ? (
                <Text
                    bottom="-40px"
                    position="absolute"
                    fontSize="8pt"
                    opacity="0.4"
                    width="100px"
                >
                    Clique aqui para trocar de foto.
                </Text>
            ) : (
                <></>
            )}
        </VStack>
    );
}
