import { Box, Image, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSettings, useTheme } from "../../context";
import { Title } from "../atoms/Title";

export function BannerCarousel({
                                   banners = [],
                                   isMobile = false,
                                   padding = "10% 5%",
                               }) {
    const { localModule, ecommerceModule, bookstoreModule } = useTheme();
    const [filteredBanners, setFilteredBanners] = useState([]);
    const { isApp } = useSettings();

    useEffect(() => {
        const newBanners = [];

        banners.forEach((b) => {
            if (b.type === "discount" && (localModule || ecommerceModule)) newBanners.push(b);
            else if (b.type === "book" && bookstoreModule) newBanners.push(b);
        });

        setFilteredBanners(newBanners);

    }, [banners]);

    if (filteredBanners.length === 0) return <></>;

    return (
        <VStack width={"100%"} align="flex-start" marginTop={"20px"}>
            {isApp ? (
                <Title paddingLeft={{ base: "5%", lg: "0px" }} textAlign="left">
                    Destaques
                </Title>
            ) : (
                <></>
            )}
            <Carousel
                style={{
                    transform: "translateY(-10%)",
                    marginBottom: "-15%",
                    width: "100%"
                }}
            >
                {filteredBanners.map((b, i) => (
                    <Carousel.Item style={{ padding }} key={i}>
                        <Box className="layered-box" borderRadius="5px">
                            <Link
                                to={
                                    b.discount
                                        ? `/detalhes/${b.discount.mode}/${b.discount.id}`
                                        : "#"
                                }
                            >
                                <Image
                                    borderRadius="5px"
                                    objectFit="cover"
                                    height="auto"
                                    maxHeight={"500px"}
                                    width="100%"
                                    src={isMobile ? b.image_mobile : b.image}
                                />
                            </Link>
                        </Box>
                    </Carousel.Item>
                ))}
            </Carousel>
        </VStack>

    );
}
