import { Box, HStack, Skeleton, VStack } from "@chakra-ui/react";
import React from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useTheme } from "../../context/index";
import { Button } from "../atoms/Button";
import { Discount } from "../atoms/Discount";
import { PercentageTag, TextTag } from "../atoms/DiscountTag";
import { Title } from "../atoms/Title";
import { getPromotions } from "../../api/discounts";

export const getTag = (data) => {
    if (data.type === "buy_and_gain")
        return (
            <TextTag>
                COMPRE
                <br /> & GANHE
            </TextTag>
        );
    else if (data.type === "treat") {
        return <TextTag>MIMO</TextTag>;
    }

    return <PercentageTag percentage={data.percentage} />;
};

export function HDiscountStack({
                                   query,
                                   queryName,
                                   title,
                                   paddingLeft = "5%",
                                   forceMode,
                                   seeMoreLink
                               }) {
    const { titleColor } = useTheme();
    const { data, isLoading, isSuccess } = useQuery([queryName, query], () =>
        getPromotions(query)
    );

    if (isLoading) {
        return (
            <VStack spacing={4} width="100%" align="flex-start">
                <Title paddingLeft="5%" textAlign="left">
                    {title}
                </Title>
                <HStack spacing={5} paddingLeft={paddingLeft}>
                    {new Array(4).fill(0).map((_, index) => (
                        <Skeleton key={index} height="100px" width="170px" />
                    ))}
                </HStack>
            </VStack>
        );
    }

    if (isSuccess && data.length === 0) return <></>;

    return (
        <VStack className="HDiscountStack" spacing={4} width="100%" align="flex-start">
            <Title color={titleColor} paddingLeft="5%" textAlign="left">
                {title}
            </Title>
            <HStack
                className="hide-scrollbar"
                spacing={5}
                paddingLeft={paddingLeft}
                paddingRight={paddingLeft}
                w="100%"
                overflowX="scroll"
                overflowY="hidden"
                align="top"
            >
                {data.map((d) => (
                    <Box
                        minWidth={{ base: "150px", md: "250px" }}
                        maxWidth={{ base: "150px", md: "250px" }}
                        key={d.id}
                        height="210px"
                    >
                        <Discount
                            key={d.id}
                            image={d.image}
                            tag={getTag(d)}
                            title={d.show_name}
                            forceMode={forceMode}
                            distance={d.distance}
                            subTitle={d.subtitle}
                            category={d.category}
                            featured={d.featured}
                            favorited={d.favorite}
                            href={`/detalhes/${d.mode}/${d.id.split("_")[1]}/`}
                        />
                    </Box>
                ))}
                {seeMoreLink ? (
                    <Box display={{ base: "block", md: "none" }}>
                        <Link
                            style={{
                                fontSize: "10pt",
                                opacity: "0.5",
                                textDecoration: "underline",
                                whiteSpace: "nowrap",
                                paddingTop: "45px"
                            }}
                            to={{ pathname: seeMoreLink }}
                        >
                            Veja todos {">>"}
                        </Link>
                    </Box>
                ) : (
                    <></>
                )}
            </HStack>
            {seeMoreLink ? (
                <Box
                    display={{ base: "none", md: "block" }}
                    paddingLeft={paddingLeft}
                >
                    <Button fontSize="8pt" height="30px">
                        <Link
                            style={{ textDecoration: "none!important" }}
                            to={{ pathname: seeMoreLink }}
                        >
                            Veja todos {">>"}
                        </Link>
                    </Button>
                </Box>
            ) : (
                <></>
            )}
        </VStack>
    );
}
