import { Button, Image, Input, Select, Stack, useToast, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { partiuClient } from "../../axios";
import { useForm } from "react-hook-form";
import { useTheme } from "../../context";

export function PlayhubLogin() {
    const { register, handleSubmit, getValues } = useForm();
    const { thirdPartyLogin, primaryColor, primaryFontColor } = useTheme();
    const toast = useToast();

    const agreementsQuery = useQuery("playhub-isps", () =>
        partiuClient.integrations.playhub.listISPS()
    );

    function redirect(slug, token) {
        const url = `https://playhub.partiu.com.br?token=${token}&nome=Cliente%20Playhub`;
        const link = document.createElement("a");

        link.href = url;
        document.body.appendChild(link);
        link.click();
    }

    const loginPlayhubMutation = useMutation(
        (data) =>
            partiuClient.integrations.playhub.login(
                data.username,
                data.password,
                data.isp
            ),
        {
            onSuccess(data) {
                let slug = "playhub";
                localStorage.setItem(
                    "auto-login",
                    JSON.stringify({ token: data.token, slug })
                );

                redirect(slug, data.token);
            },
            onError(error) {
                if (error.response.status === 403) {
                    toast({
                        title: "Erro",
                        description:
                            "Usuário não está habilitado à usar o convênio Playhub. Entre em contato com o suporte.",
                        status: "error"
                    });
                } else {
                    toast({
                        title: "Erro",
                        description:
                            "Não foi possível fazer login com os dados colocados. Reveja e tente novamente",
                        status: "error"
                    });
                }
            }
        }
    );

    useEffect(() => {
        let savedData = localStorage.getItem("auto-login");

        if (!savedData) return;

        try {
            savedData = JSON.parse(savedData);
            if (savedData.slug && savedData.token)
                redirect(savedData.slug, savedData.token);
        } catch {
            localStorage.removeItem("auto-login");
        }
    }, []);

    if (!thirdPartyLogin) return <></>;

    return (
        <VStack
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="fixed"
            left="0px"
            top="0px"
            width="100%"
            height="100%"
            zIndex="100"
            backgroundColor="#FAFAFA"
        >
            <form onSubmit={handleSubmit(loginPlayhubMutation.mutate)}>
                <VStack
                    backgroundColor="white"
                    borderRadius="5px"
                    boxShadow="0px 0px 10px rgba(0,0,0,0.3)"
                    padding="20px"
                    spacing={3}
                >
                    <Image
                        height="120px"
                        src="https://partiu-static.s3.amazonaws.com/Logos/logoPlayhub.png"
                    />
                    <Select required {...register("isp")} paddingTop="15px">
                        <option>Selecione um provedor</option>
                        {(agreementsQuery.data || []).map((a) => (
                            <option key={a.codigo} value={a.codigo}>
                                {a.nome}
                            </option>
                        ))}
                    </Select>
                    <Stack direction={{ base: "column", lg: "row" }}>
                        <Input
                            {...register("username")}
                            placeholder="Username"
                            required
                        />
                        <Input
                            {...register("password")}
                            placeholder="Password"
                            type="password"
                            required
                        />
                    </Stack>
                    <Button
                        type="submit"
                        isLoading={loginPlayhubMutation.isLoading}
                        isFullWidth
                        backgroundColor={primaryColor}
                        color={primaryFontColor}
                    >
                        Fazer Login
                    </Button>
                </VStack>
            </form>
        </VStack>
    );
}
