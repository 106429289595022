import React from "react";
import { Center } from "@chakra-ui/react";
import { useContext } from "react";
import { useTheme } from "../../context/index";

export const RoundedIconButton = React.forwardRef(
    (
        {
            icon,
            onClick = () => {},
            size = "40px",
            iconClassName,
            color = null,
            ...style
        },
        ref
    ) => {
        const { primaryColor } = useTheme();

        return (
            <Center
                onClick={onClick}
                width={size}
                height={size}
                borderRadius="1000px"
                backgroundColor="white"
                boxShadow="0px 0px 10px rgba(0,0,0,0.2)"
                cursor="pointer"
                transition="0.2s"
                _hover={{
                    marginTop: "-5px",
                    boxShadow: "0px 5px 10px rgba(0,0,0,0.2)"
                }}
                {...style}
            >
                <i
                    ref={ref}
                    className={icon + " " + iconClassName}
                    style={{ fontSize: 17, color: color || primaryColor }}
                ></i>
            </Center>
        );
    }
);
