import React from "react";
import { useContext } from "react";
import ReactInputMask from "react-input-mask";
import { useTheme } from "../../context/index";

export function RFHMaskedInput({ hookForm, ...props }) {
    return (
        <ReactInputMask
            style={{
                fontSize: "16px",
                paddingRight: "1rem",
                backgroundColor: "transparent",
                height: "46px",
                borderRadius: "0px",
                letterSpacing: "0.48px",
                borderBottom: "1px solid rgb(226, 232, 240)",
                borderColor: "rgb(226, 232, 240)",
                width: "100%"
            }}
            {...props}
            {...hookForm}
        />
    );
}
