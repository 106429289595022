import { Button, InputGroup, InputRightElement } from "@chakra-ui/react"
import { useState } from "react"
import { Input } from "./Input"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function PasswordInput(props) {
    const [show, setShow] = useState(false)
    const handleClick = () => setShow(!show)
  
    return (
        <InputGroup>
            <Input
                type={show ? 'text' : 'password'}
                {...props}
            />
            <InputRightElement width='4.5rem'>
                <Button bg="transparent" h='1.75rem' size='sm' onClick={handleClick}>
                    {show ?
                        <FontAwesomeIcon
                            opacity="1"
                            icon={faEyeSlash}
                        />
                        : <FontAwesomeIcon
                            opacity="1"
                            icon={faEye}
                        />
                    }
                </Button>
            </InputRightElement>
        </InputGroup>
    )
}