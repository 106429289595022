import { DrawerCloseButton, Image, VStack } from "@chakra-ui/react";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { ReactEpubViewer, EpubViewer } from "react-epub-viewer";
import { Box, Typography } from "@material-ui/core";

import { faArrowLeft, faArrowRight, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Progress } from "@chakra-ui/progress";
import { useTheme, useSettings } from "../../context";
import path from "path";

export const DrmEbook = () => {
    const { state } = useLocation();
    const navigate = useNavigate();

    const { primaryColor, titleColor, logo } = useTheme();
    const { isApp, isIOS } = useSettings();

    const viewerRef = useRef(undefined);
    const [location, setLocation] = useState(null);
    const [page, setPage] = useState(0);

    const [bookStyle, setBookStyle] = useState({
        fontFamily: 'Origin',
        fontSize: 18,
        lineHeight: 1.4,
        marginVertical: 5
    });

    const [bookOption, setBookOption] = useState({
        flow: "scrolled-doc",
        resizeOnOrientationChange: true,
        spread: "auto"
    });

    const onPageMove = (type) => {
        const node = viewerRef.current;
        if (!node || !node.prevPage || !node.nextPage) return;

        type === "PREV" && node.prevPage();
        type === "NEXT" && node.nextPage();
    };

    const onPageChange = (page) => setPage(page);

    return (
        <VStack style={{
            height: '100vh',
            overflow: 'hidden'
        }}>
            <Box style={{
                position: 'fixed',
                zIndex: 99999,
                left: 0,
                top: 0,
                width: '100vw',
                height: '4vh',
                display: 'flex',
                backgroundColor: primaryColor,
                padding: 4,
            }}
            >

                <Typography style={{
                    width: '100vw',
                    textAlign: 'center',
                    fontFamily: "Open Sans",
                    fontSize: "13pt",
                    fontWeight: "600",
                    color: titleColor,
                    letterSpacing: "0.03rem",
                    userSelect: 'none'
                }}>
                    {page.chapterName ? `Capítulo ${page.chapterName}` : state?.title}
                </Typography>
                <Typography
                    onClick={() => navigate(-1)}
                    style={{
                        textAlign: 'center',
                        fontFamily: "Open Sans",
                        fontSize: "13pt",
                        fontWeight: "600",
                        paddingRight: 16,
                        color: titleColor,
                        letterSpacing: "0.03rem",
                        userSelect: 'none',
                        cursor: 'pointer'
                    }}>
                    <FontAwesomeIcon
                        color="rgba(0,0,0,0.75)"
                        size="lg"
                        icon={faClose}
                    />
                </Typography>
            </Box>
            <Box
                style={{
                    position: 'relative',
                    width: '100vw',
                    height: (path.extname(state?.url).includes("pdf") || (isApp && isIOS)) ? '96vh' : '89vh',
                    marginTop: '4vh',
                    overflowX: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    '&::WebkitScrollbar': {
                        width: '0px',
                        background: 'transparent'
                    },
                    '&::WebkitScrollbarThumb': {
                        background: '#ddd',
                        borderRadius: '10px'
                    },
                    '&::WebkitScrollbarThumb:hover': {
                        background: '#ccc'
                    },
                    '&::WebkitScrollbarTrack': {
                        background: 'transparent'
                    },
                }}
            >

                {isApp && isIOS ? (
                    <div style={{ width: "100%", height: "100%" }}>
                        <iframe title="pdf" sandbox="allow-scripts allow-same-origin" src={`https://docs.google.com/viewer?url=${state?.pdf}&embedded=true`} style={{ width: "100%", height: "100%" }} frameBorder="0"></iframe>
                        <Image
                            src={logo}
                            width="40px"
                            height="40px"
                            objectFit="contain"
                            position="absolute"
                            right="12px"
                            top={"12px"}
                            backgroundColor={"#FFF"}
                        ></Image>
                    </div>) :
                    path.extname(state?.url).includes("pdf") ?
                        <div style={{ width: "100%", height: "100%" }}>
                            <iframe title="pdf" sandbox="allow-scripts allow-same-origin" src={`https://docs.google.com/viewer?url=${state?.url}&embedded=true`} style={{ width: "100%", height: "100%" }} frameborder="0"></iframe>
                            <Image
                                src={logo}
                                width="40px"
                                height="40px"
                                objectFit="contain"
                                position="absolute"
                                right="12px"
                                top={"12px"}
                                backgroundColor={"#FFF"}
                            ></Image>
                        </div>
                        :
                        <ReactEpubViewer
                            url={state?.url}
                            ref={viewerRef}
                            viewerOption={bookOption}
                            onPageChange={onPageChange}
                            viewerStyle={bookStyle}
                        /*
                    viewerLayout={{
                        MIN_VIEWER_WIDTH: 300,
                        MIN_VIEWER_HEIGHT: 300,
                        VIEWER_HEADER_HEIGHT: 64,
                        VIEWER_FOOTER_HEIGHT: 60,
                        VIEWER_SIDEMENU_WIDTH: 0
                    }}*/
                        />

                }

            </Box>

            {(!path.extname(state?.url).includes("pdf") && !isIOS) &&
                <Box style={{
                    position: 'fixed',
                    zIndex: 99999,
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    height: '7vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: primaryColor,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 12

                }}
                >
                    <Progress width="90%" size="xs" value={((page.currentPage * 100) / page.totalPage)} colorScheme="gray" />

                    <Box style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    >
                        <Typography style={{ width: '10%', textAlign: 'center' }} onClick={() => onPageMove('PREV')}>
                            <FontAwesomeIcon icon={faArrowLeft} color={titleColor} />
                        </Typography>
                        <Typography style={{
                            width: '55%', textAlign: 'center',
                            fontFamily: "Open Sans",
                            fontSize: "13pt",
                            fontWeight: "600",
                            color: titleColor,
                            letterSpacing: "0.03rem",
                            userSelect: 'none'
                        }}>
                            {!isNaN(((page.totalPage - page.currentPage) / 2).toFixed(0)) ? ((page.totalPage - page.currentPage) / 2).toFixed(0) : 0} minutos
                        </Typography>
                        <Typography style={{
                            width: '15%', textAlign: 'center',
                            fontFamily: "Open Sans",
                            fontSize: "13pt",
                            fontWeight: "600",
                            color: titleColor,
                            letterSpacing: "0.03rem",
                            userSelect: 'none'
                        }}>
                            {!isNaN(((page.currentPage * 100) / page.totalPage).toFixed(1)) ? ((page.currentPage * 100) / page.totalPage).toFixed(1) : 0}%
                        </Typography>
                        <Typography style={{ width: '15%', textAlign: 'center' }} onClick={() => onPageMove('NEXT')}>
                            <FontAwesomeIcon icon={faArrowRight} color={titleColor} />
                        </Typography>
                    </Box>
                </Box>}
        </VStack >
    );
};
