import { Checkbox, Link, Spacer, Text, UnorderedList, useToast, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Button } from "../../../components/atoms/Button";
import { ExternalLink } from "../../../components/atoms/ExternalLink";
import { Input } from "../../../components/atoms/Input";
import { PasswordInput } from "../../../components/atoms/PasswordInput";
import { RFHMaskedInput } from "../../../components/atoms/RFHMaskedInput";
import { Title } from "../../../components/atoms/Title";
import { useTheme } from "../../../context/index";
import { ValidationError } from "../../../components/molecules/ValidationError";
import { LoginWrapper } from "./LoginWrapper";
import { useNavigate } from "react-router-dom";
import { useLogin } from "../context/login.context";
import { createProfile } from "../../../api/profile";
import { useProfile } from "../../../context/user";

export function Register() {
    const navigate = useNavigate()
    const { register, handleSubmit, setValue, getValues } = useForm();
    const { login } = useLogin();
    const { refreshProfile } = useProfile();
    const { primaryColor, primaryFontColor } = useTheme();
    const toast = useToast();
    const { validatedDocument, validatedPromocode } = useLogin();
    const [acceptedTerms, setAcceptedTerms] = useState(false);
    
    const registerMutation = useMutation(
        (data) =>
            createProfile({
                ...data,
                cpf: validatedDocument || getValues("cpf"),
                promocode: validatedPromocode ? validatedPromocode.toUpperCase() : undefined
            }),
        {
            onSuccess(response) {
                login(response.data.token);
                refreshProfile()
                navigate('/')
            },
            onError(error) {
                if (error.response.status === 400) {
                    toast({
                        title: "Oops!",
                        description: "Revise as informações do formulário e tente novamente.",
                        status: "error",
                        duration: 10000,
                        isClosable: true
                    });

                    return;
                }

                toast({
                    title: "Oops!",
                    description: `Erro ${error.response.status}: Entre em contato com nossa equipe`,
                    status: "error"
                });
            }
        }
    );


    useEffect(() => {
        if (validatedDocument){
            setValue("cpf", validatedDocument);
        }
    }, [setValue, validatedDocument]);

    return (
        <LoginWrapper>
            <VStack
                height="100%"
                spacing={5}
                width={{ base: "100%", lg: "80%" }}
                marginTop={{ base: "0px", md: "0%" }}
            >
                <Title
                    color={primaryColor}
                    fontSize="28px"
                >
                    Informações Pessoais
                </Title>
                <Text
                    color={primaryColor}
                    textAlign="justify"
                >
                    Complete as informações de cadastro.
                </Text>
                <VStack width="100%">
                    <form
                        onSubmit={handleSubmit(registerMutation.mutate)}
                        style={{ width: "100%" }}
                    >
                        <VStack
                            paddingBottom="40px"
                            paddingTop="20px"
                            spacing={4}
                            width="100%"
                        >
                            <Input
                                placeholder="Nome Completo *"
                                required
                                hookForm={register("full_name")}
                            />
                            <Input
                                placeholder="E-mail *"
                                type="email"
                                hookForm={register("email")}
                                required
                            />
                            <RFHMaskedInput
                                placeholder="Celular *"
                                required
                                mask="(99) 9 9999-9999"
                                hookForm={register("telephone")}
                            />
                            <RFHMaskedInput
                                placeholder="CPF *"
                                mask="999.999.999-99"
                                disabled={validatedDocument}
                                hookForm={register("cpf")}
                                required
                            />
                            <PasswordInput
                                placeholder="Senha *"
                                hookForm={register("password")}
                                required
                            />
                            <UnorderedList
                                textAlign="left"
                                paddingLeft="5%"
                                width="100%"
                                fontSize="8pt"
                                opacity="0.5"
                            >
                                <li>Pelo menos 8 caracteres.</li>
                                <li>Não ser uma senha comum. Ex: senha123.</li>
                                <li>Ter pelo menos uma letra.</li>
                            </UnorderedList>
                            <Spacer />
                            <Checkbox
                                size="lg"
                                colorScheme="green"
                                checked={acceptedTerms}
                                onChange={(e) => setAcceptedTerms(e.target.checked)}
                                width="100%"
                            >
                                <Text fontSize="12pt">
                                    Aceito os{" "}
                                    <ExternalLink to="https://app.partiu.com.br/legal/termos-de-uso">
                                        Termos de Uso
                                    </ExternalLink>
                                </Text>
                            </Checkbox>
                            <Spacer />
                            <ValidationError error={registerMutation.isError && registerMutation.error.response.status === 400 ? registerMutation.error.response.data : undefined}/>
                            <Button
                                isDisabled={!acceptedTerms}
                                backgroundColor={primaryColor}
                                color={primaryFontColor}
                                width="100%"
                                type="submit"
                                isLoading={registerMutation.isLoading}
                            >
                                Registrar
                            </Button>
                            <Link
                                href="#"
                                fontSize="14px"
                                textDecoration="underline !important"
                                onClick={() => navigate('/login')}
                            >
                                Selecionar outro método
                            </Link>
                        </VStack>
                    </form>
                </VStack>
            </VStack>
        </LoginWrapper>
    );
}
