import React, { useEffect, useState } from "react";

import { Box, Button, Divider, Flex, Heading, HStack, Stack, Text, useToast, VStack } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { getBookStorePlans, getPlans } from "../../api/plans";
import { Card } from "../../components/atoms/Card";
import { useSettings, useTheme } from "../../context";
import { Hero } from "../AboutTheClub/components/Hero";
import { TopMenu } from "../../components/organisms/TopMenu";
import { PlanSelector } from "./components/PlanSelector";
import { PlanDetail } from "./components/PlanDetail";
import { PlanForm } from "./components/PlanForm";
import { Link, Navigate, useLocation } from "react-router-dom";
import { Footer } from "../../components/organisms/Footer";
import { useProfile } from "../../context/user";
import { LoginNeededCard } from "./components/LoginNeededCard";
import { AddAgreementButton } from "../Profile/components/AddAgreement";

export default function PlansPage() {
    const toast = useToast();
    const { plans: userPlans, logged } = useProfile();
    const { state } = useLocation();
    const { id, name, bookstoreModule, bookstoreType, integratedLogin } = useTheme();
    const { isLoading, data = [] } = useQuery("plans", () => getPlans(id), {
        enabled: !!id
    });
    const [selectedPlan, setSelectedPlan] = useState(0);

    const { isApp, isMobile } = useSettings();

    const getHeroMessage = () => {
        if (data[selectedPlan].type === "company") return `Tenha seu próprio Clube de vantagens com descontos exclusivos para membros ${name}`
        
        if (bookstoreModule) return `Procurando um incentivo para ler mais? Assine agora.`
        
        return 'Assine agora o clube de vantagens'
    }

    useEffect(() => {
        if (state?.showToast) toast({
            status: "error",
            title: "Assine Já!",
            description: `Observamos que você ainda não é um assinante. Para realizar o download ${bookstoreType === "book" ? "dos Livros" : "das Revistas"} você precisa ser um assinante ativo.`
        });
    }, [state]);

    useEffect(() => {
        for (let p in data) {
            if (userPlans && userPlans.map(up => up.id).indexOf(data[p].id) === -1) return setSelectedPlan(p);
        }
    }, [data]);

    if (!isLoading && data.length == 0) return <div></div>;

    if (!logged) return <Navigate to={{ pathname: integratedLogin ? "/login/parceiro" : "/login",  search: 'redirectUrl=/combos'}} />;

    return (
        <VStack paddingBottom="70px">
            <TopMenu />
            <Box paddingTop={{ base: "80px", lg: "0px" }}></Box>
            <Hero hideDiscounts={bookstoreModule} height={"100px"} hideButton={true}
                forceText={isLoading ? null : (getHeroMessage())} />
            {isMobile && isApp && <Stack
                zIndex={10}
                spacing={10}
                width="80%"
                margin="auto"
                position="relative"
                direction={{ base: "column", lg: "row" }}
                transform="translateY(-30px)"
                marginBottom="50px"
            >
                <Card
                    padding="30px 20px"
                    maxWidth={{ base: "100%", lg: "320px" }}
                >
                    <PlanSelector
                        plans={data}
                        selectedPlan={selectedPlan}
                        setSelectedPlan={setSelectedPlan}
                    />
                    <PlanDetail paddingTop={"10px"} plan={data[selectedPlan] || null} />
                </Card>
                <Card>
                    <Flex width={"100%"} height={"100%"} direction={"column"}>
                        <Heading paddingBottom={"20px"} fontSize="20px">Quer saber mais?</Heading>
                        <Text>
                            Consulte no navegador para saber mais informações e preços!
                        </Text>
                        <Divider paddingTop={"15px"} marginTop={"auto"} />
                        <Flex height={"20px"} />
                    </Flex>
                </Card>
                {/* <Card>
                    <Flex width={"100%"} height={"100%"} direction={"column"}>
                        <Heading paddingBottom={"20px"} fontSize="20px">É cliente {name}?</Heading>
                        <Text>
                            Alguns clientes {name} possuem acesso gratuito. O seu combo dependerá do tipo da velocidade contratada.
                        </Text>
                        <Divider paddingTop={"15px"} marginTop={"auto"} />
                        <Flex height={"20px"} />
                        <Link style={{ textAlign: "end" }} to={"/login"}>
                            <Button>
                                Faça Login
                            </Button>
                        </Link>
                    </Flex>
                </Card> */}
            </Stack>}
            {!isMobile && !isApp &&
                <Stack
                    zIndex={10}
                    spacing={10}
                    width="80%"
                    margin="auto"
                    position="relative"
                    direction={{ base: "column", lg: "row" }}
                    transform="translateY(-30px)"
                    marginBottom="50px"
                >
                    <Card>
                        <Flex width={"100%"} height={"100%"} direction={"column"}>
                            <Heading paddingBottom={"20px"} fontSize="20px">Possui codigo promocional ou já é cliente?</Heading>
                            <Text>
                                Insira seu CPF ou código promocional abaixo e ganhe uma assinatura gratis.
                            </Text>
                            <Divider paddingTop={"15px"} marginTop={"auto"} />
                            <Flex height={"20px"} />
                            <AddAgreementButton>
                                <Button>
                                    Verificar agora
                                </Button>
                            </AddAgreementButton>
                        </Flex>
                    </Card>
                    <Card
                        padding="30px 20px"
                        maxWidth={{ base: "100%", lg: "320px" }}
                    >
                        <PlanSelector
                            plans={data}
                            selectedPlan={selectedPlan}
                            setSelectedPlan={setSelectedPlan}
                        />
                        <HStack width="100%" justify="center" textAlign="center">
                            <Heading paddingTop="10px" alignSelf="flex-start" fontSize="14pt">
                                R$
                            </Heading>
                            <Heading fontSize="40pt">
                                {!isLoading ? data[selectedPlan].value.split(".")[0] : null}
                            </Heading>
                            <Heading paddingTop={"10px"} fontSize="20pt" alignSelf={"flex-start"}>
                                ,{!isLoading ? data[selectedPlan].value.split(".")[1] : null}
                            </Heading>
                            <Heading alignSelf="flex-end" fontSize="8pt">
                                Mensais
                            </Heading>
                        </HStack>
                        <Divider paddingTop="30px" />
                        <PlanDetail paddingTop={"30px"} plan={data[selectedPlan] || null} />
                    </Card>
                    
                    {logged ? <PlanForm plan={data[selectedPlan] || null} /> : <LoginNeededCard />}
                </Stack>}
        </VStack>
    );
}
