import { Box, Flex, Heading, HStack, Image, Text } from "@chakra-ui/react";
import { faHeart, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../../context/index";
import { ExclusiveTag, TextTag } from "./DiscountTag";

export const Discount = ({
    image,
    mode,
    tag = <></>,
    title,
    subTitle,
    category = "",
    distance,
    forceMode = "cover",
    neighborhood,
    href = "",
    favorited = false,
    exclusive = false
}) => {
    const { primaryColor } = useTheme();

    return (
        <Link style={{ textDecoration: "none" }} to={href}>
            <Flex
                className={`discount-${mode}`}
                height={{
                    base: !subTitle ? "200px" : "220px",
                    md: "320px"
                }}
                direction="column"
                cursor="pointer"
                position="relative"
                transition="0.2s"
                minWidth="160px"
            >
                {distance && distance !== "0m" ? (
                    <TextTag
                        containerProps={{
                            position: "absolute",
                            right: "0px",
                            bottom: { base: "110px", md: "160px" },
                            zIndex: 900,
                            height: "20px",
                            width: "48px",
                            borderRadius: "0.1rem"
                        }}
                        fontSize="8pt"
                    >
                        {distance}
                    </TextTag>
                ) : (
                    <></>
                )}
                {
                    exclusive && (
                        <ExclusiveTag
                            containerProps={{
                                position: "absolute",
                                left: "0px",
                                bottom: { base: "110px", md: "160px" },
                                zIndex: 900,
                                height: "20px",
                                borderRadius: "0.1rem"
                            }}
                            fontSize="8pt"
                        >
                            Exclusivo
                        </ExclusiveTag>
                    )
                }
                <Flex
                    width="100%"
                    minHeight={{
                        base: !subTitle ? "60%" : "50%"
                    }}
                    maxHeight={{
                        base: !subTitle ? "60%" : "50%"
                    }}
                    boxSizing="border-box"
                    overflow="hidden"
                    className="layered-box"
                >
                    <Image
                        objectFit={forceMode}
                        src={image}
                        transition="0.5s"
                        width="100%"
                        height="100%"
                        flexShrink="0"
                        _hover={{
                            transform: "scale(1.5)"
                        }}
                        backgroundColor="white"
                    />
                </Flex>
                <Box position="absolute" top="0px" right="0px">
                    {tag}
                </Box>
                <Flex
                    paddingTop="10px"
                    align="flex-start"
                    direction="column"
                    textAlign="left"
                    fontFamily="Open Sans !important"
                >
                    <HStack justify="space-between" width="100%">
                        <Heading
                            textAlign="left"
                            fontSize={{ base: "9.5pt", md: "11pt" }}
                            fontFamily="Montserrat"
                            letterSpacing="0.02em"
                            color={primaryColor}
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            width={{ base: "130px", md: "300px" }}
                        >
                            {title.toUpperCase()}
                        </Heading>
                        {favorited ? (
                            <FontAwesomeIcon
                                color="#e31b23"
                                opacity="1"
                                icon={faHeart}
                            />
                        ) : (
                            <></>
                        )}
                    </HStack>
                    {subTitle ? (
                        <Heading
                            display={{ base: "initial", lg: "none" }}
                            fontSize="8pt"
                            color="#707070"
                            paddingTop="2px"
                            fontWeight="normal"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                            width="150px"
                        >
                            {subTitle}
                        </Heading>
                    ) : (
                        <></>
                    )}
                    <Heading
                        fontSize={{ base: "8pt", lg: "10pt" }}
                        color="#BABABA"
                        paddingTop="2px"
                        fontWeight="normal"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        width={{ base: "150px", md: "250px" }}
                    >
                        {category} {neighborhood ? " | " + neighborhood : ""}
                        {mode ? " | " + mode : ""}
                    </Heading>
                </Flex>
            </Flex>
        </Link>
    );
};
