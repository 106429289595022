import React from "react";

import { Box, VStack } from "@chakra-ui/react";

import { TopMenu } from "../../components/organisms/TopMenu";

import { getMyMonthlyBooks } from "../../api/books";
import { MyBooksInfiniteListByMonth } from "../Books/components/MyBooksInfiniteListByMonth";
import { BookModal } from "../Books/components/BookModal";
import { useTheme } from "../../context";

export default function MyBooks() {

    const [selectedBook, setSelectedBook] = new React.useState(null)

    const {id} = useTheme();


    return (
        <VStack paddingBottom={{ base: "50px", lg: "70px", "2xl": "80px" }}>
            <TopMenu />
            <BookModal isOpen={selectedBook !== null} onClose={() => setSelectedBook(null)} {...selectedBook} agreement={id} />
            <Box padding="5%"  paddingTop={{ base: "48px" }} width={"100%"}>
                <MyBooksInfiniteListByMonth getFunc={getMyMonthlyBooks}
                    params={{ search: "", category: null, agreement: id }} onSelectBook={setSelectedBook}/>
            </Box>
        </VStack>
    );
}
