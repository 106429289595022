import React, { useEffect, useState } from "react";
import { axiosInstance, setAccessToken } from "../axios";
import { getDataFromParameters } from "../utils";
import { APP_EVENTS, spawnAppEvent } from "../utils/app";
import { storage } from "../utils/storage";

export function parseProfileData(profile) {
    spawnAppEvent(APP_EVENTS.SET_USER_ID, profile.id);

    return {
        logged: true,
        name: profile.full_name,
        photo: profile.image,
        createdAt: profile.created_at,
        email: profile.email,
        telephone: profile.telephone,
        cpf: profile.cpf,
        activeAgreement: profile.active_agreement,
        agreements: profile.agreements,
        allowedPushNotifications: profile.allowed_push_notifications,
        allowedPromotionalEmails: profile.allowed_promotional_emails,
        allowedWhatsapp: profile.allowed_whatsapp,
        econometer: profile.econometer || { target: 0, progress: 0 },
        mustUpdateInfo: false,
        updateProfile: () => {
        },
        refreshProfile: () => {
        },
        subscribed: profile.subscribed,
        plans: profile.plans,
        subscriptions: profile.subscriptions || [],
    };
}

export const noUserData = {
    logged: false,
    name: null,
    photo: null,
    email: null,
    cpf: null,
    telephone: null,
    subscribed: false,
    createdAt: new Date(),
    econometer: { target: 0, progress: 0 },
    subscriptions: [],
    updateProfile: () => {
    },
    refreshProfile: () => {
    }
};

export async function loginUser() {
    const data = getDataFromParameters();
    let token = data.token || storage.getToken();

    console.log(data.token, storage.getToken(), token)

    if (token && (!storage.getToken() || storage.getToken() !== token)) {
        storage.setToken(token);
    }

    if (token) setAccessToken(token)

    if (
        window.location.pathname === "/legal/termos-de-uso" ||
        !storage.getToken()
    )
        return noUserData;

    const response = await axiosInstance.get('profile/');

    const profile = parseProfileData(response.data)

    if (
        (profile.cpf && profile.cpf.includes("i_")) ||
        (!profile.cpf && !profile.email)
    ) {
        profile.mustUpdateInfo = true;
    }

    return profile;
}

export const UserContext = React.createContext(noUserData);

export function UserProvider({ children }) {
    const [data, setData] = useState(null);


    const refreshProfile = async () => {
        try {
            const profile = await loginUser();
            setData(profile);
        } catch {
            if (storage.getToken()) storage.removeToken();
            if (storage.getAutoLogin()) storage.setAutoLogin(null);
        }
                // window.location.href = '/';
    };

    const updateProfile = (user) => {
        setData(parseProfileData(user))
    }

    useEffect(() => {
        const asyncFn = async () => {
            await refreshProfile();
        }

        asyncFn();
    },[]);

    return (
        <UserContext.Provider value={{...data, updateProfile, refreshProfile, resetProfile: () => setData(noUserData)}}>
            {children}
        </UserContext.Provider>
    );
}

export function useProfile() {
    return React.useContext(UserContext);
}
