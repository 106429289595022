import React, { useEffect, useState } from "react";
import { Text, useDisclosure, VStack } from "@chakra-ui/react";
import { Button } from "../../../components/atoms/Button";
import { VirtualCard } from "./VirtualCards";
import { ActivationModal } from "./ActivationModal";
import { CinemaModal } from "./CinemaModal";
import { CPFModal } from "./CPFModal";
import { Title } from "../../../components/atoms/Title";
import { useProfile } from "../../../context/user";
import { useNavigate } from "react-router-dom";
import { PlanDrawer } from "../../../components/organisms/PlanDrawer";
import { useSettings, useTheme } from "../../../context";
import { QuantityChooser } from "./QuantityChooser";
import { checkUserPermission } from "../../../api/profile";

export const UseDiscount = ({
    mode,
    discount,
    link,
    company,
    category,
    promotions,
    coupon = null,
    initialValue = 0
}) => {
    const navigate = useNavigate();

    const _repeatShake = useState(0);
    const [quantity, setQuantity] = useState(1);
    const disclosure = useDisclosure();
    const planDisclosure = useDisclosure();
    const cinemaDisclosure = useDisclosure();
    const cpfModalDisclosure = useDisclosure();
    const { logged, cpf } = useProfile();
    const { isApp, isMobile } = useSettings();

    const { integratedLogin, id } = useTheme();

    useEffect(() => {
        const interval = setInterval(() => {
            _repeatShake[1](_repeatShake[0] + 1);
        }, 4000);

        return () => clearInterval(interval);
    }, [_repeatShake]);

    function howToUse() {
        if (mode === "local") {
            return "Mostre o cartao virtual usando o botão abaixo e apresente-o ao estabelecimento.";
        }

        if (coupon) {
            return "Acesse o site usando o link abaixo e use o cupom no fim da compra.";
        }

        return "Acesse o site usando o link premiado abaixo, seu desconto será aplicado automaticamente.";
    }

    async function onClick() {

        if (!logged)
        return navigate(
            { pathname: integratedLogin ? "/login/parceiro" : "/login" },
            { state: { showToast: true } }
        );



        if (!cpf){
            cpfModalDisclosure.onOpen();
            return
        }

        try {
            await checkUserPermission({
                agreement: id,
            })
        }catch(err){
            console.error(err)
            if (err.response.status === 403){
                navigate('/combos')
                return;
            }
            return
        }

        // if (!discount.is_subscribed) {
        //     return planDisclosure.onOpen();
        // }

        if (category === "Cinema") {

            cinemaDisclosure.onOpen();

            return
        }

        return disclosure.onOpen();
    }

    const renderButton = () => {
        const buttonText = (category === "Cinema") ? "Comprar" : "Fazer Check-in";

        if (mode === "local") {
            return (
                <Button
                    key={_repeatShake[0]}
                    className="animate_animated animateheadShake animate_delay-2s"
                    width="100%"
                    onClick={onClick}
                >
                    {buttonText}
                </Button>
            );
        } else {
            if (isApp && !isMobile) {
                return (
                    <Button
                        key={_repeatShake[0]}
                        className="animate_animated animateheadShake animate_delay-2s"
                        width="100%"
                        onClick={onClick}
                    >
                        {buttonText}
                    </Button>
                );
            } else {
                return (
                    <a href={link} target="_blank">
                        <Button
                            key={_repeatShake[0]}
                            className="animate_animated animateheadShake animate_delay-2s"
                            width="100%"
                            onClick={onClick}
                        >
                            {buttonText}
                        </Button>
                    </a>
                );
            }
        }
    };

    return (
        <>
            <PlanDrawer {...planDisclosure} />
            <ActivationModal
                discount={discount}
                company={company.id}
                link={link}
                initialValue={initialValue}
                {...disclosure}
                isOpen={mode === "online" && disclosure.isOpen}
            />
            <CPFModal 
                {...cpfModalDisclosure}
            />
            <CinemaModal
                discount={discount}
                company={company}
                promotions={promotions}
                link={link}
                initialValue={initialValue}
                quantity={quantity}
                {...cinemaDisclosure}
                isOpen={cinemaDisclosure.isOpen}
            />
            {logged ? (
                <VirtualCard
                    discount={discount}
                    company={company.id}
                    initialValue={initialValue}
                    {...disclosure}
                    isOpen={mode === "local" && disclosure.isOpen}
                />
            ) : (
                <></>
            )}
            <VStack
                display="block"
                bgColor="white"
                p="20px 20px"
                borderTop="2px"
                borderRadius="5px"
                borderColor="gray.800"
                filter="drop-shadow(0 0 0.75rem rgba(0,0,0,0.2))"
                alignItems="flex-start"
                textAlign="left"
                width={{ base: "100%", lg: "100%" }}
                zIndex="10"
            >
                <Title pb="10px">Usar o desconto</Title>
                <Text paddingBottom="10px">{howToUse()}</Text>
                {coupon && logged && discount.is_subscribed && (
                    <Text className="Coupon" fontWeight="bold">CUPOM: {coupon.toUpperCase()}</Text>
                )}
                {discount.is_product && <QuantityChooser discount={discount} quantity={quantity} onChangeQuantity={setQuantity} />}
                {renderButton()}
            </VStack>
        </>
    );
};
