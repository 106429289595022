import React from "react";
import { WhatsappBall } from "./components/atoms/WhatsappBall";
import { DownloadComponent } from "./components/molecules/DownloadPopUp";
import { SettingsProvider } from "./context/index";
import { ThemeProvider } from "./context/theme";
import { PartiuRouter } from "./routes/Routes";
import { UserProvider } from "./context/user";
import { GeolocationProvider } from "./context/geolocation";
import { BrowserRouter as Router } from "react-router-dom";
import { ErrorBoundary } from "./ErrorBoundary";
import { LoginProvider } from "./pages/Login/context/login.context";

export const App = () => {
    return (
        <SettingsProvider>
            <UserProvider>
                <ThemeProvider>
                    <GeolocationProvider>
                        <ErrorBoundary>
                            <LoginProvider>
                                <Router>
                                    <PartiuRouter />
                                </Router>
                            </LoginProvider>
                        </ErrorBoundary>
                        {!window.location.pathname.includes("ebook") && <WhatsappBall />}
                        <DownloadComponent />
                    </GeolocationProvider>
                </ThemeProvider>
            </UserProvider>
        </SettingsProvider>
    );
};
