import {
    Tab,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack,
    Input, Box
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { Title } from "../../components/atoms/Title";
import { CustomTab } from "../../components/molecules/CustomTabs";
import { UserEconometer } from "../../components/organisms/UserEconometer";
import { CustomTabList } from "../../components/organisms/CustomTabList";
import { useSettings, useTheme } from "../../context/index";
import { useProfile } from "../../context/user";
import { Agreements } from "./components/Agreements";
import { Preferences } from "./components/Preferences";
import { ProfileData } from "./components/ProfileData";
import { CurrentLocation } from "../../components/molecules/CurrentLocation";
import { Menu } from "./components/Menu";
import { AccountDependants } from "../../components/organisms/AccountDependants";

export function ProfilePage() {
    const {
        name: agreementName,

        defaultBackgroundColor
    } = useTheme();
    const { name, subscriptions, logged } = useProfile();
    const { isApp, agreementApp } = useSettings();
    const { integratedLogin, econometerModule } = useTheme();
    const [searchParams] = useSearchParams();
    const [tabIndex, setTabIndex] = useState(0);
    
    const handleTabsChange = (index) => {
        setTabIndex(index)
    }

    useEffect(() => {
        if (searchParams.get("selectedTab") === 'profile'){
            setTabIndex(1)
        }
    },[searchParams])

    if (!logged) return <Navigate to={{ pathname: integratedLogin ? "/login/parceiro" : "/login" }} />;

    return (
        <VStack
            backgroundColor={defaultBackgroundColor}
            spacing={8}
            width="100%"
            overflow="hidden"
        >
            <VStack width="90%" margin="auto" spacing={1} align="flex-start">
                {econometerModule ? <UserEconometer changeImage={true} hideImage={!isApp} /> : <></>}
                <Title paddingTop="20px" fontSize="20px">
                    {name}
                </Title>
                <Text
                    paddingBottom="10px"
                    color="#495057"
                    opacity="0.5"
                    fontSize="13px"
                >
                    {agreementName}
                </Text>
                <CurrentLocation />
            </VStack>
            <Tabs isLazy width="100%" index={tabIndex} onChange={handleTabsChange}>
                <CustomTabList paddingLeft={{ sm: "0%", lg: "5%" }}>
                    <CustomTab>Menu</CustomTab>

                    <CustomTab>Dados Pessoais</CustomTab>
                    {subscriptions && subscriptions.plan &&
                        <CustomTab>Dependentes</CustomTab>}
                    <CustomTab>Preferências</CustomTab>
                    {agreementApp == null ? (
                        <CustomTab>Adesões</CustomTab>
                    ) : (
                        <></>
                    )}
                </CustomTabList>
                <TabPanels
                    height={{ base: "60vh", lg: "51vh" }}
                    paddingBottom="50px"
                    overflowY="auto"
                    align="center"
                >
                    <TabPanel>
                        <Menu />
                    </TabPanel>

                    <TabPanel>
                        <ProfileData />
                    </TabPanel>
                    {subscriptions && subscriptions.plan &&
                    <TabPanel>
                        <Box width={"90%"} margin={"auto"}>
                            <AccountDependants />
                        </Box>
                    </TabPanel>}
                    <TabPanel>
                        <Preferences />
                    </TabPanel>
                    {agreementApp == null ? (
                        <TabPanel>
                            <Agreements />
                        </TabPanel>
                    ) : (
                        <></>
                    )}
                </TabPanels>
            </Tabs>
        </VStack>
    );
}
